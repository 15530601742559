.archive-now {
	.now-header {
		color: #002D6F;
		font-size: 2.75rem;
		font-weight: bold;
		text-align: center;
		margin-bottom: 2.5rem;
		@include md {
			font-size: 1.75rem;
		}
	}
	.grid-main {
		position: relative;
	}
	.sec-now {
		background-color: #F9F9F9;
		padding: 5rem 0 20rem;
		@include md {
			padding: 2.5rem 0 5rem;
		}
		.now-socials {
			position: absolute;
			top: -3rem;
			right: 1rem;
			@include md {
				position: static;
				text-align: right;
				margin-bottom: .5rem;
			}
			.fb_iframe_widget span {
				vertical-align: baseline !important;
			}
		}
		.now {
			&.now-preview {
				text-align: center;
				border-bottom: solid 1px #D9D9D9;
				margin-bottom: 2rem;
				.now-photo {
					overflow: hidden;
					img {
						transition: transform .25s;
					}
					&:hover,
					&:focus {
						img {
							transform: scale(1.05);
						}
					}
				}
				.now-body {
					padding: 1.375rem 0;
					.now-title {
						margin-bottom: 1rem;
						@include md {
							font-size: 1.25rem;
						}
						a {
							display: block;
						}
					}
				}
			}
			.now-photo {
				display: block;
				position: relative;
				padding-top: 42.509603072983355%;
				background-color: #ccc;
			}
			.now-body {
				padding: 2.5rem 0;
				.now-title {
					color: #0A1331;
					font-size: 1.5625rem;
					font-weight: normal;
					margin-bottom: 3rem;
					@include md {
						font-size: 1.375rem;
						margin-bottom: 2rem;
					}
				}
				.now-read-more {
					margin-top: 2.5rem;
				}
			}
		}
	}
}
