@charset "UTF-8";
/* mixinsの読み込み */
/* variablesの読み込み */
/******************************************
* font-size
******************************************/
/******************************************
* ie11のみ適応
******************************************/
.mt0 {
  margin-top: 0px !important; }

.mr0 {
  margin-right: 0px !important; }

.mb0 {
  margin-bottom: 0px !important; }

.ml0 {
  margin-left: 0px !important; }

.pt0 {
  padding-top: 0px !important; }

.pr0 {
  padding-right: 0px !important; }

.pb0 {
  padding-bottom: 0px !important; }

.pl0 {
  padding-left: 0px !important; }

.mt5 {
  margin-top: 5px !important; }

.mr5 {
  margin-right: 5px !important; }

.mb5 {
  margin-bottom: 5px !important; }

.ml5 {
  margin-left: 5px !important; }

.pt5 {
  padding-top: 5px !important; }

.pr5 {
  padding-right: 5px !important; }

.pb5 {
  padding-bottom: 5px !important; }

.pl5 {
  padding-left: 5px !important; }

.mt10 {
  margin-top: 10px !important; }

.mr10 {
  margin-right: 10px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.ml10 {
  margin-left: 10px !important; }

.pt10 {
  padding-top: 10px !important; }

.pr10 {
  padding-right: 10px !important; }

.pb10 {
  padding-bottom: 10px !important; }

.pl10 {
  padding-left: 10px !important; }

.mt15 {
  margin-top: 15px !important; }

.mr15 {
  margin-right: 15px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.ml15 {
  margin-left: 15px !important; }

.pt15 {
  padding-top: 15px !important; }

.pr15 {
  padding-right: 15px !important; }

.pb15 {
  padding-bottom: 15px !important; }

.pl15 {
  padding-left: 15px !important; }

.mt20 {
  margin-top: 20px !important; }

.mr20 {
  margin-right: 20px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.ml20 {
  margin-left: 20px !important; }

.pt20 {
  padding-top: 20px !important; }

.pr20 {
  padding-right: 20px !important; }

.pb20 {
  padding-bottom: 20px !important; }

.pl20 {
  padding-left: 20px !important; }

.mt25 {
  margin-top: 25px !important; }

.mr25 {
  margin-right: 25px !important; }

.mb25 {
  margin-bottom: 25px !important; }

.ml25 {
  margin-left: 25px !important; }

.pt25 {
  padding-top: 25px !important; }

.pr25 {
  padding-right: 25px !important; }

.pb25 {
  padding-bottom: 25px !important; }

.pl25 {
  padding-left: 25px !important; }

.mt30 {
  margin-top: 30px !important; }

.mr30 {
  margin-right: 30px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.ml30 {
  margin-left: 30px !important; }

.pt30 {
  padding-top: 30px !important; }

.pr30 {
  padding-right: 30px !important; }

.pb30 {
  padding-bottom: 30px !important; }

.pl30 {
  padding-left: 30px !important; }

.mt35 {
  margin-top: 35px !important; }

.mr35 {
  margin-right: 35px !important; }

.mb35 {
  margin-bottom: 35px !important; }

.ml35 {
  margin-left: 35px !important; }

.pt35 {
  padding-top: 35px !important; }

.pr35 {
  padding-right: 35px !important; }

.pb35 {
  padding-bottom: 35px !important; }

.pl35 {
  padding-left: 35px !important; }

.mt40 {
  margin-top: 40px !important; }

.mr40 {
  margin-right: 40px !important; }

.mb40 {
  margin-bottom: 40px !important; }

.ml40 {
  margin-left: 40px !important; }

.pt40 {
  padding-top: 40px !important; }

.pr40 {
  padding-right: 40px !important; }

.pb40 {
  padding-bottom: 40px !important; }

.pl40 {
  padding-left: 40px !important; }

.mt45 {
  margin-top: 45px !important; }

.mr45 {
  margin-right: 45px !important; }

.mb45 {
  margin-bottom: 45px !important; }

.ml45 {
  margin-left: 45px !important; }

.pt45 {
  padding-top: 45px !important; }

.pr45 {
  padding-right: 45px !important; }

.pb45 {
  padding-bottom: 45px !important; }

.pl45 {
  padding-left: 45px !important; }

.mt50 {
  margin-top: 50px !important; }

.mr50 {
  margin-right: 50px !important; }

.mb50 {
  margin-bottom: 50px !important; }

.ml50 {
  margin-left: 50px !important; }

.pt50 {
  padding-top: 50px !important; }

.pr50 {
  padding-right: 50px !important; }

.pb50 {
  padding-bottom: 50px !important; }

.pl50 {
  padding-left: 50px !important; }

.mt55 {
  margin-top: 55px !important; }

.mr55 {
  margin-right: 55px !important; }

.mb55 {
  margin-bottom: 55px !important; }

.ml55 {
  margin-left: 55px !important; }

.pt55 {
  padding-top: 55px !important; }

.pr55 {
  padding-right: 55px !important; }

.pb55 {
  padding-bottom: 55px !important; }

.pl55 {
  padding-left: 55px !important; }

.mt60 {
  margin-top: 60px !important; }

.mr60 {
  margin-right: 60px !important; }

.mb60 {
  margin-bottom: 60px !important; }

.ml60 {
  margin-left: 60px !important; }

.pt60 {
  padding-top: 60px !important; }

.pr60 {
  padding-right: 60px !important; }

.pb60 {
  padding-bottom: 60px !important; }

.pl60 {
  padding-left: 60px !important; }

.mt65 {
  margin-top: 65px !important; }

.mr65 {
  margin-right: 65px !important; }

.mb65 {
  margin-bottom: 65px !important; }

.ml65 {
  margin-left: 65px !important; }

.pt65 {
  padding-top: 65px !important; }

.pr65 {
  padding-right: 65px !important; }

.pb65 {
  padding-bottom: 65px !important; }

.pl65 {
  padding-left: 65px !important; }

.mt70 {
  margin-top: 70px !important; }

.mr70 {
  margin-right: 70px !important; }

.mb70 {
  margin-bottom: 70px !important; }

.ml70 {
  margin-left: 70px !important; }

.pt70 {
  padding-top: 70px !important; }

.pr70 {
  padding-right: 70px !important; }

.pb70 {
  padding-bottom: 70px !important; }

.pl70 {
  padding-left: 70px !important; }

.mt75 {
  margin-top: 75px !important; }

.mr75 {
  margin-right: 75px !important; }

.mb75 {
  margin-bottom: 75px !important; }

.ml75 {
  margin-left: 75px !important; }

.pt75 {
  padding-top: 75px !important; }

.pr75 {
  padding-right: 75px !important; }

.pb75 {
  padding-bottom: 75px !important; }

.pl75 {
  padding-left: 75px !important; }

.mt80 {
  margin-top: 80px !important; }

.mr80 {
  margin-right: 80px !important; }

.mb80 {
  margin-bottom: 80px !important; }

.ml80 {
  margin-left: 80px !important; }

.pt80 {
  padding-top: 80px !important; }

.pr80 {
  padding-right: 80px !important; }

.pb80 {
  padding-bottom: 80px !important; }

.pl80 {
  padding-left: 80px !important; }

.mt85 {
  margin-top: 85px !important; }

.mr85 {
  margin-right: 85px !important; }

.mb85 {
  margin-bottom: 85px !important; }

.ml85 {
  margin-left: 85px !important; }

.pt85 {
  padding-top: 85px !important; }

.pr85 {
  padding-right: 85px !important; }

.pb85 {
  padding-bottom: 85px !important; }

.pl85 {
  padding-left: 85px !important; }

.mt90 {
  margin-top: 90px !important; }

.mr90 {
  margin-right: 90px !important; }

.mb90 {
  margin-bottom: 90px !important; }

.ml90 {
  margin-left: 90px !important; }

.pt90 {
  padding-top: 90px !important; }

.pr90 {
  padding-right: 90px !important; }

.pb90 {
  padding-bottom: 90px !important; }

.pl90 {
  padding-left: 90px !important; }

.mt95 {
  margin-top: 95px !important; }

.mr95 {
  margin-right: 95px !important; }

.mb95 {
  margin-bottom: 95px !important; }

.ml95 {
  margin-left: 95px !important; }

.pt95 {
  padding-top: 95px !important; }

.pr95 {
  padding-right: 95px !important; }

.pb95 {
  padding-bottom: 95px !important; }

.pl95 {
  padding-left: 95px !important; }

.mt100 {
  margin-top: 100px !important; }

.mr100 {
  margin-right: 100px !important; }

.mb100 {
  margin-bottom: 100px !important; }

.ml100 {
  margin-left: 100px !important; }

.pt100 {
  padding-top: 100px !important; }

.pr100 {
  padding-right: 100px !important; }

.pb100 {
  padding-bottom: 100px !important; }

.pl100 {
  padding-left: 100px !important; }

.mt105 {
  margin-top: 105px !important; }

.mr105 {
  margin-right: 105px !important; }

.mb105 {
  margin-bottom: 105px !important; }

.ml105 {
  margin-left: 105px !important; }

.pt105 {
  padding-top: 105px !important; }

.pr105 {
  padding-right: 105px !important; }

.pb105 {
  padding-bottom: 105px !important; }

.pl105 {
  padding-left: 105px !important; }

.mt110 {
  margin-top: 110px !important; }

.mr110 {
  margin-right: 110px !important; }

.mb110 {
  margin-bottom: 110px !important; }

.ml110 {
  margin-left: 110px !important; }

.pt110 {
  padding-top: 110px !important; }

.pr110 {
  padding-right: 110px !important; }

.pb110 {
  padding-bottom: 110px !important; }

.pl110 {
  padding-left: 110px !important; }

.mt115 {
  margin-top: 115px !important; }

.mr115 {
  margin-right: 115px !important; }

.mb115 {
  margin-bottom: 115px !important; }

.ml115 {
  margin-left: 115px !important; }

.pt115 {
  padding-top: 115px !important; }

.pr115 {
  padding-right: 115px !important; }

.pb115 {
  padding-bottom: 115px !important; }

.pl115 {
  padding-left: 115px !important; }

.mt120 {
  margin-top: 120px !important; }

.mr120 {
  margin-right: 120px !important; }

.mb120 {
  margin-bottom: 120px !important; }

.ml120 {
  margin-left: 120px !important; }

.pt120 {
  padding-top: 120px !important; }

.pr120 {
  padding-right: 120px !important; }

.pb120 {
  padding-bottom: 120px !important; }

.pl120 {
  padding-left: 120px !important; }

.mt125 {
  margin-top: 125px !important; }

.mr125 {
  margin-right: 125px !important; }

.mb125 {
  margin-bottom: 125px !important; }

.ml125 {
  margin-left: 125px !important; }

.pt125 {
  padding-top: 125px !important; }

.pr125 {
  padding-right: 125px !important; }

.pb125 {
  padding-bottom: 125px !important; }

.pl125 {
  padding-left: 125px !important; }

.mt130 {
  margin-top: 130px !important; }

.mr130 {
  margin-right: 130px !important; }

.mb130 {
  margin-bottom: 130px !important; }

.ml130 {
  margin-left: 130px !important; }

.pt130 {
  padding-top: 130px !important; }

.pr130 {
  padding-right: 130px !important; }

.pb130 {
  padding-bottom: 130px !important; }

.pl130 {
  padding-left: 130px !important; }

.mt135 {
  margin-top: 135px !important; }

.mr135 {
  margin-right: 135px !important; }

.mb135 {
  margin-bottom: 135px !important; }

.ml135 {
  margin-left: 135px !important; }

.pt135 {
  padding-top: 135px !important; }

.pr135 {
  padding-right: 135px !important; }

.pb135 {
  padding-bottom: 135px !important; }

.pl135 {
  padding-left: 135px !important; }

.mt140 {
  margin-top: 140px !important; }

.mr140 {
  margin-right: 140px !important; }

.mb140 {
  margin-bottom: 140px !important; }

.ml140 {
  margin-left: 140px !important; }

.pt140 {
  padding-top: 140px !important; }

.pr140 {
  padding-right: 140px !important; }

.pb140 {
  padding-bottom: 140px !important; }

.pl140 {
  padding-left: 140px !important; }

.mt145 {
  margin-top: 145px !important; }

.mr145 {
  margin-right: 145px !important; }

.mb145 {
  margin-bottom: 145px !important; }

.ml145 {
  margin-left: 145px !important; }

.pt145 {
  padding-top: 145px !important; }

.pr145 {
  padding-right: 145px !important; }

.pb145 {
  padding-bottom: 145px !important; }

.pl145 {
  padding-left: 145px !important; }

.mt150 {
  margin-top: 150px !important; }

.mr150 {
  margin-right: 150px !important; }

.mb150 {
  margin-bottom: 150px !important; }

.ml150 {
  margin-left: 150px !important; }

.pt150 {
  padding-top: 150px !important; }

.pr150 {
  padding-right: 150px !important; }

.pb150 {
  padding-bottom: 150px !important; }

.pl150 {
  padding-left: 150px !important; }

.mt0 {
  margin-top: 0px !important; }

.mr0 {
  margin-right: 0px !important; }

.mb0 {
  margin-bottom: 0px !important; }

.ml0 {
  margin-left: 0px !important; }

.mt-5 {
  margin-top: -5px !important; }

.mr-5 {
  margin-right: -5px !important; }

.mb-5 {
  margin-bottom: -5px !important; }

.ml-5 {
  margin-left: -5px !important; }

.mt-10 {
  margin-top: -10px !important; }

.mr-10 {
  margin-right: -10px !important; }

.mb-10 {
  margin-bottom: -10px !important; }

.ml-10 {
  margin-left: -10px !important; }

.mt-15 {
  margin-top: -15px !important; }

.mr-15 {
  margin-right: -15px !important; }

.mb-15 {
  margin-bottom: -15px !important; }

.ml-15 {
  margin-left: -15px !important; }

.mt-20 {
  margin-top: -20px !important; }

.mr-20 {
  margin-right: -20px !important; }

.mb-20 {
  margin-bottom: -20px !important; }

.ml-20 {
  margin-left: -20px !important; }

.mt-25 {
  margin-top: -25px !important; }

.mr-25 {
  margin-right: -25px !important; }

.mb-25 {
  margin-bottom: -25px !important; }

.ml-25 {
  margin-left: -25px !important; }

.mt-30 {
  margin-top: -30px !important; }

.mr-30 {
  margin-right: -30px !important; }

.mb-30 {
  margin-bottom: -30px !important; }

.ml-30 {
  margin-left: -30px !important; }

.mt-35 {
  margin-top: -35px !important; }

.mr-35 {
  margin-right: -35px !important; }

.mb-35 {
  margin-bottom: -35px !important; }

.ml-35 {
  margin-left: -35px !important; }

.mt-40 {
  margin-top: -40px !important; }

.mr-40 {
  margin-right: -40px !important; }

.mb-40 {
  margin-bottom: -40px !important; }

.ml-40 {
  margin-left: -40px !important; }

.mt-45 {
  margin-top: -45px !important; }

.mr-45 {
  margin-right: -45px !important; }

.mb-45 {
  margin-bottom: -45px !important; }

.ml-45 {
  margin-left: -45px !important; }

.mt-50 {
  margin-top: -50px !important; }

.mr-50 {
  margin-right: -50px !important; }

.mb-50 {
  margin-bottom: -50px !important; }

.ml-50 {
  margin-left: -50px !important; }

.mt-55 {
  margin-top: -55px !important; }

.mr-55 {
  margin-right: -55px !important; }

.mb-55 {
  margin-bottom: -55px !important; }

.ml-55 {
  margin-left: -55px !important; }

.mt-60 {
  margin-top: -60px !important; }

.mr-60 {
  margin-right: -60px !important; }

.mb-60 {
  margin-bottom: -60px !important; }

.ml-60 {
  margin-left: -60px !important; }

.mt-65 {
  margin-top: -65px !important; }

.mr-65 {
  margin-right: -65px !important; }

.mb-65 {
  margin-bottom: -65px !important; }

.ml-65 {
  margin-left: -65px !important; }

.mt-70 {
  margin-top: -70px !important; }

.mr-70 {
  margin-right: -70px !important; }

.mb-70 {
  margin-bottom: -70px !important; }

.ml-70 {
  margin-left: -70px !important; }

.mt-75 {
  margin-top: -75px !important; }

.mr-75 {
  margin-right: -75px !important; }

.mb-75 {
  margin-bottom: -75px !important; }

.ml-75 {
  margin-left: -75px !important; }

.mt-80 {
  margin-top: -80px !important; }

.mr-80 {
  margin-right: -80px !important; }

.mb-80 {
  margin-bottom: -80px !important; }

.ml-80 {
  margin-left: -80px !important; }

.mt-85 {
  margin-top: -85px !important; }

.mr-85 {
  margin-right: -85px !important; }

.mb-85 {
  margin-bottom: -85px !important; }

.ml-85 {
  margin-left: -85px !important; }

.mt-90 {
  margin-top: -90px !important; }

.mr-90 {
  margin-right: -90px !important; }

.mb-90 {
  margin-bottom: -90px !important; }

.ml-90 {
  margin-left: -90px !important; }

.mt-95 {
  margin-top: -95px !important; }

.mr-95 {
  margin-right: -95px !important; }

.mb-95 {
  margin-bottom: -95px !important; }

.ml-95 {
  margin-left: -95px !important; }

.mt-100 {
  margin-top: -100px !important; }

.mr-100 {
  margin-right: -100px !important; }

.mb-100 {
  margin-bottom: -100px !important; }

.ml-100 {
  margin-left: -100px !important; }

/******************************************
* common-style
******************************************/
* {
  box-sizing: border-box;
  margin: 0; }

a {
  color: inherit;
  text-decoration: none; }
  a.a-deco:hover, a.a-deco:focus {
    text-decoration: underline; }

@media screen and (min-width: 0) and (max-width: 878px) {
  br.wbr {
    display: none; } }

img {
  max-width: 100%;
  height: auto;
  border: none; }
  img.mix-darken {
    mix-blend-mode: darken; }
  img.fit-cover {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%; }

.fit-parent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.opacify {
  transition: opacity .2s; }
  .opacify:hover, .opacify:focus {
    filter: alpha(opacity=70);
    -moz-opacity: 0.7;
    opacity: 0.7; }

table {
  border-collapse: collapse; }

address {
  font-style: normal; }

html {
  scroll-behavior: smooth; }
  @media screen and (max-width: 959.98px) {
    html.is-locked {
      overflow: hidden; } }

body {
  padding: 0;
  margin: 0;
  line-height: 1.5;
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif; }

#grid-page-container {
  min-height: 100vh;
  display: grid;
  grid-template: "page-header" auto "page-main" 1fr "page-footer" auto /100%; }
  #grid-page-container #grid-page-header {
    grid-area: page-header; }
  #grid-page-container #grid-page-main {
    grid-area: page-main;
    padding-bottom: 8rem; }
  #grid-page-container #grid-page-footer {
    grid-area: page-footer; }

.list-none {
  list-style: none;
  padding-left: 0; }

.list-before-square > li::before {
  content: "■"; }

.container {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto; }

.wrapper-sm {
  width: 100%;
  max-width: 1040px;
  margin: 0 auto; }

.wrapper-xs {
  width: 100%;
  max-width: 890px;
  margin: 0 auto; }

.sec {
  margin-bottom: 8rem; }
  @media screen and (max-width: 959.98px) {
    .sec {
      margin-bottom: 5rem; } }

.ws-pl {
  white-space: pre-line; }

.ws-nw {
  white-space: nowrap; }

.align-left {
  text-align: left !important; }

.align-center {
  text-align: center !important; }

.align-right {
  text-align: right !important; }

.align-middle {
  vertical-align: middle !important; }

.negative-indent {
  text-indent: -1em;
  padding-left: 1em; }

.text-required {
  color: #FF0000; }

.text-danger {
  color: #D43F3A; }

.text-info {
  color: #174C88; }

.text-muted {
  color: #555555; }

.text-associate {
  color: #D63367 !important; }

.text-black {
  color: black !important; }

.ti-1 {
  text-indent: 1em; }

.font-sm {
  font-size: .875rem !important; }

.font-xs {
  font-size: .75rem !important; }

.font-lg {
  font-size: 1.25rem !important; }

.font-xl {
  font-size: 1.5rem !important; }

.font-bold {
  font-weight: bold; }

.bg-gray {
  background-color: #F7F7F7; }

@media screen {
  .screen-reader-text {
    display: none; } }

.common-h1 {
  font-size: 2.75rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 4rem; }
  @media screen and (max-width: 959.98px) {
    .common-h1 {
      font-size: 1.75rem;
      margin-bottom: 2.5rem; } }

.common-h2 {
  color: #002D6F;
  font-size: 2.125rem;
  text-align: center;
  margin-bottom: 4rem; }
  @media screen and (max-width: 959.98px) {
    .common-h2 {
      font-size: 1.5rem;
      margin-bottom: 2rem; }
      .common-h2 img {
        height: 50px; } }
  .common-h2 + .common-h2-sub {
    font-size: 1.1875rem;
    font-weight: bold;
    text-align: center;
    margin-top: -3.75rem;
    margin-bottom: 4rem; }
    @media screen and (max-width: 959.98px) {
      .common-h2 + .common-h2-sub {
        margin-top: -2rem;
        margin-bottom: 2rem; } }

.common-h3 {
  font-size: 1.25rem;
  margin-bottom: 1.5rem; }
  .common-h3.common-h3-left-border {
    padding-left: .75rem;
    border-left: solid 5px #56B9D6; }

.common-container {
  padding: 0 20px; }
  @media screen and (max-width: 959.98px) {
    .common-container {
      padding: 0; } }

.common-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  color: #5e6568; }
  .common-table tr:nth-of-type(even) {
    background-color: #e2e2e2; }
  .common-table tr th, .common-table tr td {
    text-align: left;
    padding: 1rem 2rem;
    border: solid 1px #c6c6c6; }
  .common-table tr th {
    font-weight: normal;
    width: 28%; }

.agents-news-h1 {
  font-size: 2.75rem;
  text-align: center;
  margin-bottom: 5rem; }
  @media screen and (max-width: 959.98px) {
    .agents-news-h1 {
      font-size: 1.75rem;
      margin-bottom: 3.5rem; } }
  .agents-news-h1 .ja {
    color: #6C6C6C;
    font-weight: normal; }
  .agents-news-h1 .en {
    font-weight: bold; }
    @media screen and (max-width: 959.98px) {
      .agents-news-h1 .en {
        display: inline-block; } }

.table-col-2 th, .table-col-2 td {
  text-align: left; }

.table-col-2 th {
  min-width: 10em;
  font-weight: normal;
  vertical-align: top; }

@media screen and (max-width: 959.98px) {
  .table-col-2 {
    display: block; }
    .table-col-2 tbody, .table-col-2 tr, .table-col-2 th, .table-col-2 td {
      display: block;
      width: auto; }
    .table-col-2 tr {
      margin-bottom: .75rem; }
    .table-col-2 th {
      font-weight: bold; }
    .table-col-2 td {
      padding-left: 1.5em;
      text-indent: 0; } }

.grid-col-2 {
  display: grid;
  grid-gap: 20px;
  grid-template: "left right" auto /1fr  1fr; }
  @media screen and (max-width: 959.98px) {
    .grid-col-2 {
      display: block; }
      .grid-col-2 .grid-left,
      .grid-col-2 .grid-right {
        margin-bottom: 4rem; }
      .grid-col-2.grid-left-auto .grid-left,
      .grid-col-2.grid-left-auto .grid-right, .grid-col-2.grid-right-auto .grid-left,
      .grid-col-2.grid-right-auto .grid-right {
        margin-bottom: 2rem; } }
  @media screen and (max-width: 959.98px) {
    .grid-col-2.grid-col-remain {
      display: grid; } }
  .grid-col-2.grid-align-top .grid-left,
  .grid-col-2.grid-align-top .grid-right {
    align-self: flex-start; }
  .grid-col-2.grid-align-center .grid-left,
  .grid-col-2.grid-align-center .grid-right {
    align-self: center; }
  .grid-col-2.grid-align-bottom .grid-left,
  .grid-col-2.grid-align-bottom .grid-right {
    align-self: flex-end; }
  .grid-col-2.grid-no-gap {
    grid-gap: 0;
    grid-template: "left right" auto /1fr  1fr; }
  .grid-col-2.grid-wide-gap {
    grid-gap: 40px;
    grid-template: "left right" auto /1fr  1fr; }
  .grid-col-2.grid-left-auto {
    grid-gap: 40px;
    grid-template: "left right" auto /auto 1fr; }
    .grid-col-2.grid-left-auto.grid-no-gap {
      grid-gap: 0;
      grid-template: "left right" auto /auto 1fr; }
  .grid-col-2.grid-right-auto {
    grid-gap: 40px;
    grid-template: "left right" auto /1fr  auto; }
    .grid-col-2.grid-right-auto.grid-no-gap {
      grid-gap: 0;
      grid-template: "left right" auto /1fr  auto; }
  .grid-col-2 .grid-left {
    grid-area: left; }
  .grid-col-2 .grid-right {
    grid-area: right; }

.grid-col-3 {
  display: grid;
  grid-gap: 20px;
  grid-template: "g1  g2  g3" auto /1fr 1fr 1fr; }
  @media screen and (max-width: 959.98px) {
    .grid-col-3 {
      display: block; } }
  @media screen and (max-width: 959.98px) {
    .grid-col-3.grid-col-fixed {
      display: grid; } }
  .grid-col-3 .grid-1 {
    grid-area: g1; }
  .grid-col-3 .grid-2 {
    grid-area: g2; }
  .grid-col-3 .grid-3 {
    grid-area: g3; }

.grid-page-col {
  display: grid;
  grid-gap: 80px;
  grid-template: "main side" auto /1fr  300px; }
  @media screen and (max-width: 1279.98px) {
    .grid-page-col {
      grid-gap: 40px;
      grid-template: "main side" auto /1fr  300px; } }
  @media screen and (max-width: 959.98px) {
    .grid-page-col {
      display: block; } }
  .grid-page-col .grid-main {
    grid-area: main; }
    @media screen and (max-width: 959.98px) {
      .grid-page-col .grid-main {
        margin-bottom: 5rem; } }
  .grid-page-col .grid-side {
    grid-area: side; }

.col {
  overflow: hidden; }
  .col .col-3 {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px; }
    .col .col-3 > * {
      width: calc(100% / 3 - 10px * 2 - 1px);
      max-width: calc(100% / 3 - 10px * 2);
      flex: 1 0 auto;
      margin: 0 10px 20px; }
    @media screen and (max-width: 959.98px) {
      .col .col-3 > * {
        width: calc(100% / 2 - 10px * 2 - 1px);
        max-width: calc(100% / 2 - 10px * 2);
        margin: 0 10px 20px; } }
    @media screen and (max-width: 599.98px) {
      .col .col-3 {
        margin: 0; }
        .col .col-3 > * {
          width: 100%;
          max-width: none;
          margin: 0 0 20px; } }
  .col .col-3m {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -35px; }
    .col .col-3m > * {
      width: calc(100% / 3 - 35px * 2 - 1px);
      max-width: calc(100% / 3 - 35px * 2);
      flex: 1 0 auto;
      margin: 0 35px 20px; }
    @media screen and (max-width: 959.98px) {
      .col .col-3m {
        margin: 0 -30px; }
        .col .col-3m > * {
          width: calc(100% / 2 - 30px * 2 - 1px);
          max-width: calc(100% / 2 - 30px * 2);
          margin: 0 30px 20px; } }
    @media screen and (max-width: 599.98px) {
      .col .col-3m {
        margin: 0; }
        .col .col-3m > * {
          width: 100%;
          max-width: none;
          margin: 0 0 20px; } }

.btn {
  transition: opacity .2s;
  display: inline-block;
  background: none;
  padding: 0;
  border: none;
  cursor: pointer; }
  .btn:hover, .btn:focus {
    filter: alpha(opacity=70);
    -moz-opacity: 0.7;
    opacity: 0.7; }
  .btn.btn-gray {
    background-color: #BBBBBB;
    color: white;
    font-size: .8125rem;
    text-align: center;
    padding: .875rem 1rem;
    min-width: 166px; }
    @media screen and (max-width: 599.98px) {
      .btn.btn-gray {
        padding: .5rem 1rem;
        min-width: 0; } }
  .btn.btn-sns {
    width: 26px;
    height: 26px;
    background-repeat: no-repeat;
    background-position: center; }
    .btn.btn-sns.btn-sns-fb {
      background-color: #2f58a4;
      background-image: url(../img/fb.png);
      background-size: 10px 16px; }
      @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        .btn.btn-sns.btn-sns-fb {
          background-image: url(../img/fb@2x.png); } }
    .btn.btn-sns.btn-sns-tw {
      background-color: #00b2e8;
      background-image: url(../img/tw.png);
      background-size: 16px 14px; }
      @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        .btn.btn-sns.btn-sns-tw {
          background-image: url(../img/tw@2x.png); } }
  .btn.btn-navy, .btn.btn-blue, .btn.btn-red {
    color: white;
    font-size: 1.125rem;
    font-weight: bold;
    text-align: center;
    padding: .75rem 2.5rem;
    border-radius: 10rem; }
  .btn.btn-red {
    background-color: #980114;
    min-width: 432px; }
    @media screen and (max-width: 599.98px) {
      .btn.btn-red {
        min-width: 0; } }
  .btn.btn-blue {
    background-color: #174C88;
    min-width: 316px; }
    @media screen and (max-width: 599.98px) {
      .btn.btn-blue {
        min-width: 0; } }
  .btn.btn-navy {
    background-color: #0A1331;
    font-weight: normal;
    min-width: 213px; }
    @media screen and (max-width: 599.98px) {
      .btn.btn-navy {
        min-width: 0; } }

.card {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px !important; }
  @media screen and (max-width: 599.98px) {
    .card {
      margin-bottom: 20px !important; } }
  .card:hover .card-photo .fit-parent img, .card:focus .card-photo .fit-parent img {
    transform: scale(1.05); }
  .card .card-photo {
    flex: none;
    position: relative;
    background-color: #ddd;
    padding-top: 60.5263157894737%;
    overflow: hidden; }
    .card .card-photo .fit-parent {
      overflow: hidden; }
      .card .card-photo .fit-parent img {
        transition: transform .25s; }
    .card .card-photo time {
      position: absolute;
      display: block;
      font-size: .75rem;
      left: 0;
      bottom: 0;
      background-color: black;
      color: white;
      padding: .5rem 1rem; }
  .card .card-body {
    flex: 1 0 auto;
    display: flex;
    min-height: 130px;
    flex-direction: column;
    justify-content: space-between;
    background-color: #F7F7F7;
    padding: 1rem 1.5rem; }
    .card .card-body .card-title {
      flex: none;
      font-size: .75rem;
      font-weight: bold;
      margin-bottom: .5rem; }
    .card .card-body .card-text {
      flex: none;
      font-size: .75rem;
      margin-bottom: .5rem; }
    .card .card-body .card-tag {
      flex: none; }
      .card .card-body .card-tag a {
        display: inline-block;
        background-color: white;
        font-size: .75rem;
        padding: .25rem .75rem;
        border: solid 1px #DEDDDE;
        transition: .25s; }
        .card .card-body .card-tag a:hover, .card .card-body .card-tag a:focus {
          background-color: #4360AA;
          color: white;
          border-color: #4360AA; }

.sec-title-img {
  margin-bottom: .75rem; }

.side-contents .side-content {
  margin-bottom: 2rem; }
  .side-contents .side-content dt {
    font-size: 1.125rem;
    font-weight: bold;
    margin-bottom: 2rem; }
  .side-contents .side-content dd .recommend-articles li {
    margin-bottom: 2rem; }
    .side-contents .side-content dd .recommend-articles li a:hover .ra-photo img {
      transform: scale(1.05); }
    .side-contents .side-content dd .recommend-articles li a:hover .ra-title {
      text-decoration: underline; }
    .side-contents .side-content dd .recommend-articles li a .ra-photo {
      overflow: hidden;
      position: relative;
      padding-top: 42.509603072983355%;
      margin-bottom: 1rem; }
      .side-contents .side-content dd .recommend-articles li a .ra-photo img {
        transition: transform .25s; }
    .side-contents .side-content dd .recommend-articles li a .ra-title {
      color: #0A1331; }
  .side-contents .side-content.side-content-navy, .side-contents .side-content.side-content-white {
    border-radius: 5px;
    padding: 2rem; }
  .side-contents .side-content.side-content-navy {
    background-color: #0A1331;
    color: white; }
  .side-contents .side-content.side-content-white {
    background-color: white;
    border: solid 1px #E0E0E0; }
  .side-contents .side-content.side-content-angle-list dt {
    font-size: 1rem;
    font-weight: normal; }
  .side-contents .side-content.side-content-angle-list dd ul li {
    margin-bottom: .5rem; }
    .side-contents .side-content.side-content-angle-list dd ul li a {
      display: block;
      padding-left: 1em;
      text-indent: -1em; }
      .side-contents .side-content.side-content-angle-list dd ul li a::before {
        content: "＞"; }
      .side-contents .side-content.side-content-angle-list dd ul li a:hover, .side-contents .side-content.side-content-angle-list dd ul li a:focus {
        text-decoration: underline; }

.navigation.pagination[role="navigation"] .screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden; }

.navigation.pagination[role="navigation"] .nav-links .page-numbers {
  display: inline-block;
  background-color: #ccc;
  color: white;
  padding: .5rem 1rem;
  text-decoration: none;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.125);
  border-radius: 1px;
  margin-bottom: .25rem; }
  .navigation.pagination[role="navigation"] .nav-links .page-numbers.current {
    background-color: #999; }
  .navigation.pagination[role="navigation"] .nav-links .page-numbers:hover, .navigation.pagination[role="navigation"] .nav-links .page-numbers:focus {
    background-color: #B3B3B3; }

.mb-sec {
  margin-bottom: 7rem !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: .25rem !important; }

.mb-2 {
  margin-bottom: .5rem !important; }

.mb-1.mb-2 {
  margin-bottom: .75rem !important; }

.mb-4 {
  margin-bottom: 1rem !important; }

.mb-4.mb-1 {
  margin-bottom: 1.25rem !important; }

.mb-4.mb-2 {
  margin-bottom: 1.5rem !important; }

.mb-4.mb-1.mb-2 {
  margin-bottom: 1.75rem !important; }

.mb-8 {
  margin-bottom: 2rem !important; }

.mb-8.mb-1 {
  margin-bottom: 2.25rem !important; }

.mb-8.mb-2 {
  margin-bottom: 2.5rem !important; }

.mb-8.mb-1.mb-2 {
  margin-bottom: 2.75rem !important; }

.mb-8.mb-4 {
  margin-bottom: 3rem !important; }

.mb-8.mb-4.mb-1 {
  margin-bottom: 3.25rem !important; }

.mb-8.mb-4.mb-2 {
  margin-bottom: 3.5rem !important; }

.mb-8.mb-4.mb-1.mb-2 {
  margin-bottom: 3.75rem !important; }

.mb-16 {
  margin-bottom: 4rem !important; }

.sec-btn .flex {
  display: flex;
  width: 100%; }
  .sec-btn .flex .img-wrapper {
    flex: none; }
    .sec-btn .flex .img-wrapper img {
      display: block; }
  .sec-btn .flex .title-wrapper {
    flex: 1 1 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem; }
    .sec-btn .flex .title-wrapper img {
      display: block; }
    .sec-btn .flex .title-wrapper .title {
      display: block;
      font-size: 1.375rem;
      font-weight: bold;
      margin-top: .5rem; }
  .sec-btn .flex.recruit .title-wrapper, .sec-btn .flex.gray .title-wrapper {
    background-color: #F9F9F9; }
  .sec-btn .flex.introduce .title {
    color: #123975; }

.text {
  font-size: 1rem; }
  .text .text-header {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1.5rem; }
  .text .text-subheader {
    font-size: 1.125rem;
    font-weight: bold;
    margin-bottom: 1rem; }
  .text section {
    margin-bottom: 4rem; }
  .text a {
    color: #174C88; }
    .text a:hover {
      text-decoration: underline; }
  .text p, .text ol, .text ul {
    line-height: 2;
    margin-bottom: 2rem; }
  .text ol, .text ul {
    padding-left: 1.5em; }
    .text ol.list-upper-roman, .text ul.list-upper-roman {
      list-style-type: upper-roman; }
    .text ol.list-none, .text ul.list-none {
      list-style: none;
      padding-left: 0; }
  .text hr {
    border-color: #979797;
    border-style: dashed none none;
    border-width: 1px;
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .text .border-box {
    padding: .5rem 1.25rem;
    border: solid 1px #979797;
    margin-bottom: 1.5rem; }
  .text .mb-p {
    margin-bottom: 2rem; }

.of-hidden {
  overflow: hidden; }

.f-left {
  float: left;
  margin-right: 2rem;
  margin-bottom: 1rem; }

.f-right {
  float: right;
  margin-left: 2rem;
  margin-bottom: 1rem; }

.header-common {
  margin-bottom: 5rem; }
  .header-common .grid-left img {
    display: block;
    max-width: 332px; }
  .header-common .grid-right {
    background-color: #F9F9F9;
    font-weight: bold;
    padding: 2rem; }

.box-lightblue {
  background-color: #D6E7F8;
  padding: 4rem 3.5rem 3rem;
  border-radius: 6px; }

.google-map iframe {
  width: 100%;
  border: none; }

.agent {
  display: grid;
  margin-bottom: 4rem !important;
  grid-gap: 20px;
  grid-template: "agt-photo agt-name" auto "agt-photo agt-sns"  auto "agt-intro agt-intro" 1fr /113px     1fr; }
  .agent .grid-photo {
    grid-area: agt-photo; }
    .agent .grid-photo .agent-photo {
      position: relative;
      border: solid 1px #DEDDDE;
      border-radius: 50%;
      overflow: hidden; }
      .agent .grid-photo .agent-photo::before {
        content: "";
        display: block;
        padding-top: 100%; }
  .agent .grid-name {
    grid-area: agt-name; }
    .agent .grid-name .agent-pos {
      font-size: .5625rem; }
    .agent .grid-name .agent-name {
      font-size: 1.5rem; }
  .agent .grid-sns {
    grid-area: agt-sns;
    align-self: flex-end;
    font-size: .75rem;
    text-align: right; }
  .agent .grid-intro {
    grid-area: agt-intro;
    font-size: .75rem; }

dl.contact-form > div {
  margin-bottom: 3rem; }
  @media screen and (max-width: 959.98px) {
    dl.contact-form > div {
      margin-bottom: 2rem; } }
  dl.contact-form > div dt {
    font-size: 1.5rem;
    font-weight: bold;
    padding-left: 1rem;
    margin-bottom: .5rem; }
    @media screen and (max-width: 959.98px) {
      dl.contact-form > div dt {
        font-size: 1.25rem; } }
  dl.contact-form > div dd.dd-way {
    padding-left: 1rem; }
    dl.contact-form > div dd.dd-way .err-msg {
      padding-left: 0; }
  dl.contact-form > div dd input,
  dl.contact-form > div dd textarea,
  dl.contact-form > div dd select {
    display: block;
    width: 100%;
    font-size: 1.5rem;
    border: solid 3px #DEDDDE;
    border-radius: 17px; }
    dl.contact-form > div dd input:disabled,
    dl.contact-form > div dd textarea:disabled,
    dl.contact-form > div dd select:disabled {
      opacity: .75; }
    @media screen and (max-width: 959.98px) {
      dl.contact-form > div dd input,
      dl.contact-form > div dd textarea,
      dl.contact-form > div dd select {
        font-size: 1rem; } }
  dl.contact-form > div dd input, dl.contact-form > div dd textarea {
    padding: 1.625rem 2.25rem; }
    @media screen and (max-width: 959.98px) {
      dl.contact-form > div dd input, dl.contact-form > div dd textarea {
        padding: 1rem 1.75rem; } }
    dl.contact-form > div dd input::placeholder, dl.contact-form > div dd textarea::placeholder {
      color: #DEDDDE; }
  dl.contact-form > div dd textarea {
    resize: vertical;
    min-height: 320px; }
  dl.contact-form > div dd select {
    background: none;
    appearance: none;
    background-image: url(../img/angle-down.png);
    background-size: 25px 16px;
    background-repeat: no-repeat;
    background-position: center right;
    background-position: center right 7%;
    padding: 1rem 2.25rem; }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      dl.contact-form > div dd select {
        background-image: url(../img/angle-down@2x.png); } }
    @media screen and (max-width: 959.98px) {
      dl.contact-form > div dd select {
        padding: 1rem 1.75rem; } }
    dl.contact-form > div dd select::-ms-expand {
      display: none; }
  dl.contact-form > div dd .err-msg {
    color: #D43F3A;
    font-size: 1.125rem;
    padding-left: 1rem;
    margin-top: .5rem; }
    dl.contact-form > div dd .err-msg:empty {
      display: none; }
  dl.contact-form > div dd .label-check {
    display: inline-block;
    margin-top: 1rem;
    margin-right: 3rem; }
    dl.contact-form > div dd .label-check [type="checkbox"],
    dl.contact-form > div dd .label-check [type="radio"] {
      display: none; }
      dl.contact-form > div dd .label-check [type="checkbox"]:checked + label .label-box::after,
      dl.contact-form > div dd .label-check [type="radio"]:checked + label .label-box::after {
        content: ""; }
      dl.contact-form > div dd .label-check [type="checkbox"]:disabled + label,
      dl.contact-form > div dd .label-check [type="radio"]:disabled + label {
        opacity: .75; }
    dl.contact-form > div dd .label-check label {
      display: inline-block; }
      dl.contact-form > div dd .label-check label .label-box {
        position: relative;
        display: inline-block;
        width: 2.75em;
        height: 2.75em;
        border: solid 3px #DEDDDE;
        border-radius: 6px;
        margin-right: 1em;
        vertical-align: middle; }
        dl.contact-form > div dd .label-check label .label-box::after {
          content: none;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          display: block;
          width: .5em;
          height: 1.25em;
          border-color: black;
          border-style: none solid solid none;
          border-width: .25em;
          transform: translateY(-0.125em) rotate(45deg); }
      dl.contact-form > div dd .label-check label .label-str {
        user-select: none;
        vertical-align: middle; }
  dl.contact-form > div dd .scroll-box-wrapper {
    padding: 1.625rem 0;
    border: solid 3px #DEDDDE; }
    @media screen and (max-width: 959.98px) {
      dl.contact-form > div dd .scroll-box-wrapper {
        padding: 1rem 0; } }
    dl.contact-form > div dd .scroll-box-wrapper .scroll-box {
      padding: 0 2.25rem;
      max-height: 262px;
      overflow: auto;
      overflow-x: hidden; }
      @media screen and (max-width: 959.98px) {
        dl.contact-form > div dd .scroll-box-wrapper .scroll-box {
          padding: 0 1rem; } }
      dl.contact-form > div dd .scroll-box-wrapper .scroll-box p {
        margin-bottom: 0; }

.contact-submit {
  text-align: center;
  margin-top: 5rem; }
  @media screen and (max-width: 959.98px) {
    .contact-submit {
      margin-top: 3.5rem; } }

/* wordpress editor -------- */
.editor {
  overflow: hidden; }
  .editor > :first-child {
    margin-top: 0; }
  .editor .aligncenter {
    display: block;
    margin: 0 auto;
    padding-bottom: 20px; }
  .editor .alignright {
    float: right;
    padding-left: 20px;
    padding-bottom: 20px; }
    @media screen and (min-width: 0) and (max-width: 878px) {
      .editor .alignright {
        float: none;
        padding-left: 0;
        padding-bottom: 0;
        width: 100%; } }
  .editor .alignleft {
    float: left;
    padding-right: 20px;
    padding-bottom: 20px; }
    @media screen and (min-width: 0) and (max-width: 878px) {
      .editor .alignleft {
        float: none;
        padding-right: 0;
        padding-bottom: 0;
        width: 100%; } }
  .editor img[class*="wp-image-"], .editor img[class*="attachment-"] {
    max-width: 100%;
    height: auto; }
  .editor a {
    color: #0A1331;
    text-decoration: none; }
    .editor a:hover {
      color: #69f; }
  .editor h1 {
    margin: 50px 0 40px;
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 1.25; }
    @media screen and (min-width: 0) and (max-width: 878px) {
      .editor h1 {
        font-size: 1.25rem; } }
  .editor h2 {
    margin: 40px 0 30px;
    font-size: 150%;
    font-weight: bold;
    line-height: 1.25; }
  .editor h3 {
    margin: 30px 0 20px;
    font-size: 125%;
    font-weight: bold;
    line-height: 1.25; }
  .editor p, .editor ul, .editor ol, .editor table {
    font-size: .875rem;
    line-height: 1.875;
    margin-bottom: .875rem; }
  .editor li {
    margin-bottom: 10px; }
  .editor ul {
    list-style-type: disc;
    padding-left: 1.5em; }
  .editor ol {
    list-style-type: decimal;
    padding-left: 1.5em; }
  .editor hr {
    margin: 20px 0;
    border: none;
    border-top: 1px solid #999; }
  .editor table {
    width: auto !important;
    height: auto !important;
    border-collapse: collapse; }
  .editor th, .editor td {
    padding: 10px 20px;
    border: 1px solid #b5b5b5; }
  .editor th {
    background-color: #eee; }
  .editor strong {
    font-weight: bold; }
  .editor em {
    font-style: italic; }
  .editor del {
    text-decoration: line-through; }

#header {
  padding: 2rem 0; }
  #header .grid-header {
    display: grid;
    grid-gap: 40px;
    grid-template: "h-logo ... h-nav" auto /auto   1fr auto; }
    @media screen and (max-width: 959.98px) {
      #header .grid-header {
        grid-template: "h-logo h-nav" auto /1fr    auto; } }
    #header .grid-header .grid-logo {
      grid-area: h-logo;
      align-self: center; }
    #header .grid-header .grid-nav {
      grid-area: h-nav;
      align-self: center;
      position: relative; }
      #header .grid-header .grid-nav #openMenu {
        display: none; }
        @media screen and (max-width: 959.98px) {
          #header .grid-header .grid-nav #openMenu {
            display: block; } }

@media screen and (max-width: 959.98px) {
  #nav {
    position: absolute;
    z-index: 2147483647;
    top: 0;
    right: 0;
    width: 250px;
    background-color: #004D9A;
    color: white;
    padding: 1.25rem 1.5rem;
    opacity: 0;
    visibility: hidden;
    transition: visibility .25s step-end, opacity .25s; }
    #nav.is-shown {
      opacity: 1;
      visibility: visible;
      transition: visibility .25s step-start, opacity .25s; }
      #nav.is-shown + #backdrop {
        opacity: 1;
        visibility: visible;
        background-color: rgba(255, 255, 255, 0.25);
        transition: visibility .25s step-start, all .25s; } }

#nav .nav-header {
  display: none; }
  @media screen and (max-width: 959.98px) {
    #nav .nav-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.5rem; } }

#nav ul li {
  display: inline-block;
  font-size: .8125rem;
  font-weight: bold;
  margin-right: 3em; }
  #nav ul li:last-of-type {
    margin-right: 0; }
  @media screen and (max-width: 959.98px) {
    #nav ul li {
      font-weight: normal;
      margin-right: 0; } }
  #nav ul li a {
    transition: opacity .2s; }
    #nav ul li a:hover, #nav ul li a:focus {
      filter: alpha(opacity=70);
      -moz-opacity: 0.7;
      opacity: 0.7; }
    #nav ul li a.curr {
      color: #DB1C2E; }
      @media screen and (max-width: 959.98px) {
        #nav ul li a.curr {
          color: white;
          font-weight: bold; } }
  @media screen and (max-width: 959.98px) {
    #nav ul li {
      display: block;
      font-size: .875rem; }
      #nav ul li a {
        display: block;
        padding: .5rem 0; } }

#backdrop {
  display: none; }
  @media screen and (max-width: 959.98px) {
    #backdrop {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2147483646;
      opacity: 0;
      visibility: hidden;
      transition: visibility .25s step-end, all .25s; } }

.btn-menu {
  display: block;
  background: none;
  font-size: inherit;
  padding: 0;
  border: none;
  text-align: center;
  width: 50px;
  margin-left: auto; }
  .btn-menu .btn-menu-icon {
    position: relative;
    width: 50px;
    height: 39px;
    margin: 2px auto 0; }
    .btn-menu .btn-menu-icon .hamburger {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      height: 9px;
      background-color: black; }
      .btn-menu .btn-menu-icon .hamburger::before, .btn-menu .btn-menu-icon .hamburger::after {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 9px;
        background-color: black; }
      .btn-menu .btn-menu-icon .hamburger::before {
        bottom: 15px; }
      .btn-menu .btn-menu-icon .hamburger::after {
        top: 15px; }
    .btn-menu .btn-menu-icon .cross {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto; }
      .btn-menu .btn-menu-icon .cross::before, .btn-menu .btn-menu-icon .cross::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 16px;
        height: 3px;
        background-color: white;
        transform-origin: center;
        margin: auto; }
      .btn-menu .btn-menu-icon .cross::before {
        transform: rotate(45deg); }
      .btn-menu .btn-menu-icon .cross::after {
        transform: rotate(-45deg); }
  .btn-menu .btn-menu-label {
    font-size: 1.25rem; }
  .btn-menu#closeMenu {
    display: inline-block;
    width: auto;
    color: white;
    text-align: center;
    line-height: 1; }
    .btn-menu#closeMenu .btn-menu-icon {
      width: 16px;
      height: 16px;
      margin: 0 auto; }
    .btn-menu#closeMenu .btn-menu-label {
      font-size: .5625rem; }

#footer {
  position: relative;
  background-color: black;
  color: white; }
  #footer .flex-wrapper {
    display: flex;
    width: 100%; }
    #footer .flex-wrapper .flex {
      display: flex;
      width: 100%;
      align-items: center;
      min-height: 3.5rem; }
      #footer .flex-wrapper .flex .social-btns {
        flex: none;
        line-height: 1; }
        @media screen and (max-width: 599.98px) {
          #footer .flex-wrapper .flex .social-btns a + a {
            margin-left: .5rem; } }
      #footer .flex-wrapper .flex .copyright {
        flex: 1 0 0%;
        font-size: .8125rem;
        text-align: center;
        padding: .375rem 4rem .375rem 1rem; }
        #footer .flex-wrapper .flex .copyright small {
          font-size: inherit; }
  #footer .totop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 3.5rem;
    background-color: #333333;
    overflow: hidden; }
    #footer .totop::before, #footer .totop::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: .75rem;
      height: 2px;
      background-color: white; }
    #footer .totop::before {
      transform: translateX(-30%) rotate(-45deg); }
    #footer .totop::after {
      transform: translateX(30%) rotate(45deg); }

.page-index .index-h2 {
  font-size: 2.5rem;
  font-weight: normal;
  text-align: center;
  margin-bottom: 4rem; }
  @media screen and (max-width: 959.98px) {
    .page-index .index-h2 {
      font-size: 1.75rem;
      margin-bottom: 2.5rem; } }
  .page-index .index-h2 + .index-h2-sub {
    font-size: .875rem;
    text-align: center;
    line-height: 1.875;
    margin-top: -3.5rem;
    margin-bottom: 3.5rem; }
    @media screen and (max-width: 959.98px) {
      .page-index .index-h2 + .index-h2-sub {
        margin-top: -2.5rem;
        margin-bottom: 2rem; } }

.page-index .sec-visual .wrapper {
  position: relative;
  background-image: url(../img/index/visual.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 654px; }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .page-index .sec-visual .wrapper {
      background-image: url(../img/index/visual@2x.jpg); } }
  @media screen and (max-width: 959.98px) {
    .page-index .sec-visual .wrapper {
      min-height: 0; } }
  .page-index .sec-visual .wrapper section {
    position: absolute;
    top: 23%;
    left: 8%;
    width: 41.666666666666667%;
    font-size: .8125rem; }
    @media screen and (max-width: 959.98px) {
      .page-index .sec-visual .wrapper section {
        position: static;
        width: auto;
        background-color: rgba(255, 252, 242, 0.85);
        padding: 2rem; } }
    .page-index .sec-visual .wrapper section h1 {
      font-size: 2rem;
      font-weight: normal;
      margin-bottom: 1rem; }
    .page-index .sec-visual .wrapper section p {
      line-height: 1.875; }
      .page-index .sec-visual .wrapper section p + p {
        margin-top: 1rem; }

.page-index .sec-news dl.news {
  display: inline-flex;
  flex-direction: column;
  text-align: left;
  max-width: 100%; }
  .page-index .sec-news dl.news > div {
    display: flex;
    margin-bottom: .5rem; }
    @media screen and (max-width: 959.98px) {
      .page-index .sec-news dl.news > div {
        display: block;
        margin-bottom: .75rem; } }
    .page-index .sec-news dl.news > div dt {
      flex: none;
      margin-right: 2rem; }
    .page-index .sec-news dl.news > div dd {
      flex: 1 1 auto;
      word-break: break-all; }

.page-index .sec-news .news-footer {
  text-align: right;
  margin-top: 1rem; }
  @media screen and (max-width: 959.98px) {
    .page-index .sec-news .news-footer {
      margin-top: .25rem; } }

.page-index .sec-now .now-header {
  position: relative; }
  @media screen and (max-width: 959.98px) {
    .page-index .sec-now .now-header {
      text-align: center; } }
  .page-index .sec-now .now-header .btn-gray {
    position: absolute;
    right: 0;
    bottom: 0; }
    @media screen and (max-width: 959.98px) {
      .page-index .sec-now .now-header .btn-gray {
        display: none; } }

.page-index .sec-now .now-footer {
  display: none; }
  @media screen and (max-width: 959.98px) {
    .page-index .sec-now .now-footer {
      display: block;
      text-align: right; } }

.page-index .sec-agents .agents-footer {
  text-align: right; }

.page-index .sec-access {
  background-color: #F7F7F7;
  padding: 5rem 0; }
  .page-index .sec-access .access-header {
    margin-bottom: 1.75rem; }
  .page-index .sec-access p {
    font-size: .8125rem;
    margin-bottom: 1.5rem; }
  .page-index .sec-access .google-map iframe {
    height: 460px; }

.page-office .sec-greeting h3 {
  font-size: 1.875rem;
  margin-bottom: 1rem; }
  @media screen and (max-width: 959.98px) {
    .page-office .sec-greeting h3 {
      font-size: 1.25rem; } }

.page-office .sec-greeting p {
  line-height: 2; }

.page-office .sec-greeting .greeting-footer {
  margin-top: 2.5rem;
  text-align: right;
  font-weight: bold; }
  .page-office .sec-greeting .greeting-footer .pos {
    font-size: 1.0625rem;
    margin-bottom: .5rem; }
    @media screen and (max-width: 959.98px) {
      .page-office .sec-greeting .greeting-footer .pos {
        font-size: 1rem;
        margin-bottom: .25rem; } }
  .page-office .sec-greeting .greeting-footer .name {
    font-size: 2.0625rem; }
    @media screen and (max-width: 959.98px) {
      .page-office .sec-greeting .greeting-footer .name {
        font-size: 1.5rem; } }

.page-office .sec-associate .lives {
  margin-bottom: 5rem; }
  @media screen and (min-width: 0) and (max-width: 878px) {
    .page-office .sec-associate .lives {
      text-align: center; } }
  .page-office .sec-associate .lives .live1 b {
    color: #D43F3A; }
  .page-office .sec-associate .lives .live2 b {
    color: #4A90E2; }
  .page-office .sec-associate .lives .live3 b {
    color: #417505; }

.page-office .sec-associate .common-h2.text-associate {
  margin-bottom: 2rem; }

.page-office .gradient {
  padding-top: 5rem;
  background-image: linear-gradient(to bottom, #EEEEEE, rgba(216, 216, 216, 0)); }

.page-office .google-map iframe {
  height: 591px; }

.page-business .common-h1 {
  margin-bottom: 2.5rem; }

.page-business .sec-prelude {
  margin-bottom: 2rem; }
  .page-business .sec-prelude .common-h2 {
    margin-bottom: 2.5rem; }
  .page-business .sec-prelude .prelude-footer {
    margin-top: 5rem; }
    @media screen and (max-width: 959.98px) {
      .page-business .sec-prelude .prelude-footer {
        margin-top: 3.5rem; } }

.page-business .sec-visual {
  background-color: #161439;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-image: url(../img/business/visual.jpg);
  height: 57.266187050359712vw;
  color: white;
  text-align: center;
  padding-top: 7%; }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .page-business .sec-visual {
      background-image: url(../img/business/visual@2x.jpg); } }
  @media screen and (max-width: 959.98px) {
    .page-business .sec-visual {
      height: auto;
      min-height: 680px;
      padding: 4rem 0 12rem;
      text-shadow: 0 0 1px #161439; } }
  .page-business .sec-visual .wrapper-visual {
    max-width: 850px; }
    .page-business .sec-visual .wrapper-visual h2 {
      margin-bottom: 2rem; }
    @media screen and (max-width: 959.98px) {
      .page-business .sec-visual .wrapper-visual p {
        background: rgba(0, 0, 0, 0.25);
        padding: 15px;
        border-radius: 4px; } }

.page-business .sec-group .group-header {
  font-size: 1.6875rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 5rem; }
  @media screen and (max-width: 959.98px) {
    .page-business .sec-group .group-header {
      font-size: 1.5rem;
      margin-bottom: 3.5rem; } }

.page-business .sec-group .grid-company {
  grid-gap: 80px;
  grid-template: "left right" auto /1fr  1fr; }

.page-business .sec-group .company .company-photo {
  text-align: center;
  margin-bottom: 1rem; }

.page-business .sec-group .company .company-name {
  font-size: 1.6875rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 3rem; }
  @media screen and (max-width: 959.98px) {
    .page-business .sec-group .company .company-name {
      font-size: 1.375rem;
      margin-bottom: 2rem; } }

.page-business .sec-colors .grid-colors {
  margin-top: 5rem;
  grid-gap: 80px;
  grid-template: "g1  g2  g3" auto /1fr 1fr 1fr;
  text-align: center; }

.page-business .sec-colors .grid-colors-col2 {
  margin-top: 5rem;
  text-align: center; }

@media screen and (max-width: 959.98px) {
  .page-business .sec-colors .grid-1,
  .page-business .sec-colors .grid-2,
  .page-business .sec-colors .grid-3 {
    margin-bottom: 3.5rem; } }

.page-business .sec-colors .merits-wrapper {
  overflow: hidden;
  margin-top: 4rem; }
  .page-business .sec-colors .merits-wrapper .flex-merits {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -15px; }
    .page-business .sec-colors .merits-wrapper .flex-merits .merit {
      flex: 1 0 auto;
      width: calc(100% / 3 - 30px - 1px);
      max-width: calc(100% / 3 - 30px);
      text-align: center;
      margin: 0 15px 30px; }
      @media screen and (max-width: 959.98px) {
        .page-business .sec-colors .merits-wrapper .flex-merits .merit {
          width: calc(100% / 2 - 30px - 1px);
          max-width: calc(100% / 2 - 30px); } }

.page-business .sec-colors .point {
  text-align: center;
  margin-bottom: 2rem; }
  @media screen and (max-width: 959.98px) {
    .page-business .sec-colors .point {
      margin-bottom: 1.5rem; } }
  .page-business .sec-colors .point .point-square {
    position: relative;
    width: 100%;
    max-width: 136px;
    border: solid 1px #979797;
    margin: 0 auto .25rem; }
    .page-business .sec-colors .point .point-square::before {
      content: "";
      display: block;
      padding-top: 100%; }
    .page-business .sec-colors .point .point-square.point-square-yellow {
      background-color: #F3B023;
      border: none; }
    .page-business .sec-colors .point .point-square.point-square-blue {
      background-color: #4360AA;
      color: white;
      border: none; }
    .page-business .sec-colors .point .point-square.point-square-red {
      background-color: #D43F3A;
      color: white;
      border: none; }
    .page-business .sec-colors .point .point-square.point-square-green {
      background-color: #345012;
      color: white;
      border: none; }
    .page-business .sec-colors .point .point-square .fit-parent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-weight: bold; }
      .page-business .sec-colors .point .point-square .fit-parent .point-number {
        font-size: 3.125rem;
        line-height: 1.25; }
  .page-business .sec-colors .point .point-label {
    font-size: 1.0625rem; }

.page-business .sec-contact .wrapper-contact {
  max-width: 660px; }

.page-business .sec-contact .contact-header {
  color: #D43F3A;
  font-size: 2.125rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem; }
  @media screen and (max-width: 959.98px) {
    .page-business .sec-contact .contact-header {
      padding-top: 2rem;
      font-size: 1.75rem; } }

.page-business .sec-contact p {
  margin-bottom: 5rem; }
  @media screen and (max-width: 959.98px) {
    .page-business .sec-contact p {
      margin-bottom: 3.5rem; } }

.page-recruit .wrapper-foreign {
  /*---------------共通設定----------------*/
  /*---------------------header-----------------------*/
  /*---------------------main-----------------------*/
  /*---------------------check-----------------------*/
  /*---------------------RE/MAXエージェントとは-----------------------*/
  /*---------------------RE/MAXエージェントの特徴-----------------------*/
  /*
h3#tokucho4{
	background:url(../img/recruit/tokucho4.png) no-repeat;
	width:783px;
	height:96px;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
	margin-left:40px;
	margin-bottom:20px;
}

h3#tokucho5{
	background:url(../img/recruit/tokucho5.png) no-repeat;
	width:783px;
	height:96px;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
	margin-left:40px;
	margin-bottom:20px;
}

*/
  /*---------------------お問合せ-----------------------*/
  /*---------------------こだわり-----------------------*/
  /*---------------------収入-----------------------*/
  /*---------------------エージェントの声-----------------------*/
  /*---------------------お問い合わせ・ご相談-----------------------*/
  /*---------------------footer-----------------------*/
  /*---------------------thanks-----------------------*/ }
  .page-recruit .wrapper-foreign * {
    box-sizing: content-box; }
  .page-recruit .wrapper-foreign html, .page-recruit .wrapper-foreign body, .page-recruit .wrapper-foreign div, .page-recruit .wrapper-foreign h1, .page-recruit .wrapper-foreign h2, .page-recruit .wrapper-foreign h3, .page-recruit .wrapper-foreign h4, .page-recruit .wrapper-foreign h5, .page-recruit .wrapper-foreign h6, .page-recruit .wrapper-foreign p, .page-recruit .wrapper-foreign blockquote, .page-recruit .wrapper-foreign pre,
  .page-recruit .wrapper-foreign addres, .page-recruit .wrapper-foreign ul, .page-recruit .wrapper-foreign ol, .page-recruit .wrapper-foreign dl, .page-recruit .wrapper-foreign dt, .page-recruit .wrapper-foreign dd, .page-recruit .wrapper-foreign table, .page-recruit .wrapper-foreign th, .page-recruit .wrapper-foreign td, .page-recruit .wrapper-foreign form, .page-recruit .wrapper-foreign fieldset {
    margin: 0;
    padding: 0;
    line-height: 1.6;
    font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    list-style-type: none;
    color: #000; }
  .page-recruit .wrapper-foreign table, .page-recruit .wrapper-foreign td {
    border-collapse: collapse;
    border-spacing: 0; }
  .page-recruit .wrapper-foreign ul, .page-recruit .wrapper-foreign ol {
    list-style: none; }
  .page-recruit .wrapper-foreign a {
    text-decoration: none;
    color: #000;
    outline: none;
    overflow: hidden; }
  .page-recruit .wrapper-foreign img {
    border: 0; }
  .page-recruit .wrapper-foreign ing, .page-recruit .wrapper-foreign input {
    vertical-aligin: bottom; }
  .page-recruit .wrapper-foreign body {
    background-color: #fff;
    margin: 0px auto; }
  .page-recruit .wrapper-foreign .clearfix:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden; }
  .page-recruit .wrapper-foreign .clearfix {
    zoom: 1; }
  .page-recruit .wrapper-foreign #header {
    height: 90px;
    width: 1000px;
    margin: 0px auto; }
  .page-recruit .wrapper-foreign h1#logo {
    width: 185px;
    height: 61px;
    background: url(../img/recruit/logo.png) no-repeat;
    text-indent: 100%;
    display: block;
    float: left;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 15px; }
  .page-recruit .wrapper-foreign #head_tel {
    width: 250px;
    height: 61px;
    float: right;
    padding-right: 10px;
    margin-top: 15px; }
  .page-recruit .wrapper-foreign #head_mail {
    width: 220px;
    height: 52px;
    background: url(../img/recruit/head_mail.png) no-repeat;
    text-indent: 100%;
    display: block;
    float: right;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 19px; }
  .page-recruit .wrapper-foreign #head_mail a {
    width: 100%;
    height: 100%;
    text-indent: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden; }
  .page-recruit .wrapper-foreign #head_mail:hover {
    opacity: 0.8; }
  .page-recruit .wrapper-foreign #main {
    width: 1000px;
    height: 460px;
    background: url(../img/recruit/main3.jpg) no-repeat;
    text-indent: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    margin: 0px auto;
    clear: both; }
  .page-recruit .wrapper-foreign #mail a {
    width: 100%;
    height: 100%;
    text-indent: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden; }
  .page-recruit .wrapper-foreign #check {
    width: 1000px;
    height: 100px;
    background: url(../img/recruit/check.jpg) no-repeat;
    text-indent: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    margin: 0px auto; }
  .page-recruit .wrapper-foreign #check_back {
    background: #00499c;
    height: 100px; }
  .page-recruit .wrapper-foreign #back1 {
    background: url(../img/recruit/back1.jpg) repeat; }
  .page-recruit .wrapper-foreign #check2 {
    background: url(../img/recruit/check2.png) no-repeat;
    width: 1000px;
    height: 350px;
    text-indent: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    margin: 0px auto; }
  .page-recruit .wrapper-foreign #check2 a {
    width: 100%;
    height: 100%;
    text-indent: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden; }
  .page-recruit .wrapper-foreign #check3 {
    background: url(../img/recruit/check3.jpg) no-repeat;
    width: 1000px;
    height: 450px;
    text-indent: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    margin: 0px auto;
    margin-bottom: 50px; }
  .page-recruit .wrapper-foreign #check3 a {
    width: 100%;
    height: 100%;
    text-indent: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden; }
  .page-recruit .wrapper-foreign #title1 {
    background: url(../img/recruit/title1.png) no-repeat;
    width: 1000px;
    height: 90px;
    text-indent: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    margin: 0px auto;
    margin-bottom: 20px; }
  .page-recruit .wrapper-foreign .remax_p p {
    width: 1000px;
    margin: 0px auto;
    margin-bottom: 30px;
    font-size: 17px;
    letter-spacing: 0.05em;
    line-height: 30px;
    text-align: justify; }
  .page-recruit .wrapper-foreign #table {
    width: 1000px;
    height: 359px;
    margin: 0px auto;
    margin-bottom: 50px; }
  .page-recruit .wrapper-foreign #title2 {
    background: url(../img/recruit/title2.jpg) no-repeat;
    width: 1000px;
    height: 90px;
    text-indent: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    margin: 0px auto;
    margin-bottom: 50px; }
  .page-recruit .wrapper-foreign h3#tokucho1 {
    background: url(../img/recruit/tokucho1.png) no-repeat;
    width: 783px;
    height: 96px;
    text-indent: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 40px;
    margin-bottom: 20px; }
  .page-recruit .wrapper-foreign h3#tokucho2 {
    background: url(../img/recruit/tokucho2.png) no-repeat;
    width: 783px;
    height: 96px;
    text-indent: 200%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 40px;
    margin-bottom: 20px; }
  .page-recruit .wrapper-foreign h3#tokucho3 {
    background: url(../img/recruit/tokucho3.png) no-repeat;
    width: 783px;
    height: 96px;
    text-indent: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 40px;
    margin-bottom: 20px; }
  .page-recruit .wrapper-foreign .back_blue {
    background: #00499c repeat; }
  .page-recruit .wrapper-foreign .back_in {
    width: 1000px;
    background: url(../img/recruit/back1.jpg) repeat;
    margin: 0px auto;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    padding-top: 40px;
    padding-bottom: 1px; }
  .page-recruit .wrapper-foreign .image1 img {
    float: right;
    margin-right: 40px;
    box-shadow: 2px 2px 3px #ccc;
    -moz-box-shadow: 2px 2px 3px #ccc;
    -webkit-box-shadow: 2px 2px 3px #ccc; }
  .page-recruit .wrapper-foreign .image2 img {
    float: right;
    margin-right: 40px;
    box-shadow: 2px 2px 3px #ccc;
    -moz-box-shadow: 2px 2px 3px #ccc;
    -webkit-box-shadow: 2px 2px 3px #ccc;
    margin-top: 110px; }
  .page-recruit .wrapper-foreign .image3 img {
    float: right;
    margin-right: 40px;
    box-shadow: 2px 2px 3px #ccc;
    -moz-box-shadow: 2px 2px 3px #ccc;
    -webkit-box-shadow: 2px 2px 3px #ccc;
    margin-top: -70px; }
  .page-recruit .wrapper-foreign .tokucho_p {
    width: 540px;
    margin-bottom: 30px;
    font-size: 17px;
    letter-spacing: 0.05em;
    line-height: 30px;
    float: left;
    padding-left: 50px;
    font-weight: 700;
    text-align: justify; }
  .page-recruit .wrapper-foreign .tokucho_p2 {
    width: 360px;
    margin: 0px auto;
    margin-bottom: 30px;
    font-size: 17px;
    letter-spacing: 0.05em;
    line-height: 30px;
    float: left;
    padding-left: 50px;
    font-weight: 700;
    text-align: justify; }
  .page-recruit .wrapper-foreign .bottom20 {
    margin-bottom: 60px; }
  .page-recruit .wrapper-foreign .tokucho_left {
    width: 534px; }
  .page-recruit .wrapper-foreign #tokucho_p3 {
    width: 540px;
    float: left;
    text-align: justify; }
  .page-recruit .wrapper-foreign h4 {
    color: #00499c;
    font-size: 20px;
    font-weight: bold;
    padding-top: 10px; }
  .page-recruit .wrapper-foreign .tokucho_left {
    padding-left: 50px;
    width: 534px;
    font-weight: 700;
    font-size: 17px;
    letter-spacing: 0.05em;
    line-height: 30px; }
  .page-recruit .wrapper-foreign #remaxtoha {
    margin-top: 50px; }
  .page-recruit .wrapper-foreign #title_remax {
    background: url(../img/recruit/title_remax.jpg) no-repeat;
    width: 1000px;
    height: 90px;
    text-indent: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    margin: 0px auto;
    margin-bottom: 50px; }
  .page-recruit .wrapper-foreign .black_font {
    width: 900px;
    font-size: 17px;
    letter-spacing: 0.05em;
    line-height: 30px;
    font-weight: 700;
    text-align: justify;
    margin: 0 auto 50px; }
  .page-recruit .wrapper-foreign .remaxtoha_title {
    background-color: #333;
    width: 900px;
    font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    color: #fff;
    font-size: 26px;
    text-align: center;
    font-weight: bold;
    margin: 60px auto 50px; }
  .page-recruit .wrapper-foreign .remaxtoha_images {
    width: 980px;
    margin: 0 auto 60px; }
  .page-recruit .wrapper-foreign .inquiry_back1 {
    background: #00499c repeat;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 50px; }
  .page-recruit .wrapper-foreign .inquiry_back2 {
    width: 1000px;
    height: 330px;
    background: url(../img/recruit/inquiry_back.png) no-repeat;
    margin: 0px auto; }
  .page-recruit .wrapper-foreign .inquiry_back2-1 {
    width: 1000px;
    height: 330px;
    background: url(../img/recruit/inquiry_back2.png) no-repeat;
    margin: 0px auto; }
  .page-recruit .wrapper-foreign .inquiry_back2-2 {
    width: 1000px;
    height: 330px;
    background: url(../img/recruit/inquiry_back3.png) no-repeat;
    margin: 0px auto; }
  .page-recruit .wrapper-foreign .inquiry_back2-3 {
    width: 1000px;
    height: 330px;
    background: url(../img/recruit/inquiry_back4.png) no-repeat;
    margin: 0px auto; }
  .page-recruit .wrapper-foreign .inquiry {
    width: 670px;
    float: right;
    padding-right: 40px;
    padding-top: 40px; }
  .page-recruit .wrapper-foreign .inquiry p {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: justify; }
  .page-recruit .wrapper-foreign .logo2 {
    background: url(../img/recruit/logo2.png) no-repeat;
    width: 66px;
    height: 20px;
    text-indent: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    float: left;
    padding-right: 5px;
    margin-top: 3px; }
  .page-recruit .wrapper-foreign .inquiry_mail {
    background: url(../img/recruit/inquiry_mail.png) no-repeat;
    width: 670px;
    height: 90px;
    text-indent: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    float: left;
    padding-right: 5px; }
  .page-recruit .wrapper-foreign .inquiry_mail a {
    width: 100%;
    height: 100%;
    text-indent: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden; }
  .page-recruit .wrapper-foreign .inquiry_tel {
    margin-bottom: 10px; }
  .page-recruit .wrapper-foreign .inquiry_mail:hover {
    opacity: 0.8; }
  .page-recruit .wrapper-foreign #title3 {
    background: url(../img/recruit/title3.png) no-repeat;
    width: 1000px;
    height: 90px;
    text-indent: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    margin: 0px auto;
    margin-bottom: 20px; }
  .page-recruit .wrapper-foreign #kodawari {
    width: 1000px;
    margin: 0px auto; }
  .page-recruit .wrapper-foreign #kodawari p {
    width: 1000px;
    margin: 0px auto;
    margin-bottom: 30px;
    font-size: 17px;
    line-height: 30px;
    letter-spacing: 0.05em;
    text-align: justify; }
  .page-recruit .wrapper-foreign #kodawari1 {
    width: 1000px;
    height: 419px;
    margin: 0px auto;
    margin-bottom: 50px; }
  .page-recruit .wrapper-foreign #ryotechukai {
    background: url(../img/recruit/ryotechukai.jpg) no-repeat;
    width: 500px;
    height: 419px;
    text-indent: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    float: left; }
  .page-recruit .wrapper-foreign #katatechukai {
    background: url(../img/recruit/katatechukai.jpg) no-repeat;
    width: 500px;
    height: 419px;
    text-indent: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    float: right; }
  .page-recruit .wrapper-foreign #kakoikomi {
    background: url(../img/recruit/kakoikomi.jpg) no-repeat;
    width: 1000px;
    height: 959px;
    text-indent: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden; }
  .page-recruit .wrapper-foreign #tesuryogaku {
    background: url(../img/recruit/tesuryogaku.jpg) no-repeat;
    width: 1000px;
    height: 234px;
    text-indent: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 14px; }
  .page-recruit .wrapper-foreign #kodawari3 {
    background: url(../img/recruit/kodawari3.jpg) no-repeat;
    width: 1000px;
    height: 197px;
    text-indent: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 50px; }
  .page-recruit .wrapper-foreign #title4 {
    background: url(../img/recruit/title4.png) no-repeat;
    width: 1000px;
    height: 90px;
    text-indent: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    margin: 0px auto;
    margin-bottom: 20px; }
  .page-recruit .wrapper-foreign #income {
    width: 1000px;
    margin: 0px auto; }
  .page-recruit .wrapper-foreign #income p {
    width: 1000px;
    margin: 0px auto;
    margin-bottom: 30px;
    font-size: 17px;
    line-height: 30px;
    text-align: justify; }
  .page-recruit .wrapper-foreign #income1 {
    background: url(../img/recruit/income1.jpg) no-repeat;
    width: 480px;
    height: 564px;
    text-indent: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    float: left;
    margin-bottom: 20px; }
  .page-recruit .wrapper-foreign #income2 {
    background: url(../img/recruit/income2.jpg) no-repeat;
    width: 480px;
    height: 564px;
    text-indent: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    float: right;
    margin-bottom: 20px; }
  .page-recruit .wrapper-foreign #income3 {
    background: url(../img/recruit/income3.jpg) no-repeat;
    width: 480px;
    height: 564px;
    text-indent: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    float: left;
    margin-bottom: 20px; }
  .page-recruit .wrapper-foreign #income4 {
    background: url(../img/recruit/income4.jpg) no-repeat;
    width: 480px;
    height: 564px;
    text-indent: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    float: right;
    margin-bottom: 50px; }
  .page-recruit .wrapper-foreign #title5 {
    background: url(../img/recruit/title5.png) no-repeat;
    width: 1000px;
    height: 90px;
    text-indent: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    margin: 0px auto;
    margin-bottom: 50px; }
  .page-recruit .wrapper-foreign .koe {
    width: 918px;
    margin: 0px auto;
    background-color: #fff;
    box-shadow: 2px 2px 3px #ccc;
    -moz-box-shadow: 2px 2px 3px #ccc;
    -webkit-box-shadow: 2px 2px 3px #ccc;
    border: 1px solid #eee;
    padding: 40px;
    margin-bottom: 50px; }
  .page-recruit .wrapper-foreign .case {
    width: 78px;
    height: 78px;
    background: url(../img/recruit/case_back.png) no-repeat;
    float: left;
    color: #fff;
    font-size: 44px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    padding-top: 15px;
    text-align: center;
    margin-right: 20px; }
  .page-recruit .wrapper-foreign .syozoku {
    font-size: 22px; }
  .page-recruit .wrapper-foreign .name {
    padding-right: 20px;
    margin-top: -10px;
    float: left;
    font-family: "ＭＳ ゴシック", "MS Gothic", "Osaka－等幅", Osaka-mono, monospace;
    font-size: 45px; }
  .page-recruit .wrapper-foreign .kana {
    font-size: 25px;
    padding-top: 15px;
    font-family: Arial, Helvetica, sans-serif; }
  .page-recruit .wrapper-foreign .koe_left p {
    width: 650px;
    font-size: 16px;
    border-left: 3px solid #00499c;
    padding-left: 18px;
    text-align: justify; }
  .page-recruit .wrapper-foreign .koe_name {
    height: 78px;
    border-bottom: 2px dotted #ccc;
    margin-bottom: 30px;
    padding-bottom: 30px;
    line-height: 90px; }
  .page-recruit .wrapper-foreign .koe_right {
    width: 210px;
    height: 250px;
    float: right; }
  .page-recruit .wrapper-foreign .koe_left {
    width: 668px;
    float: left; }
  .page-recruit .wrapper-foreign .koe_right img {
    margin-bottom: 10px; }
  .page-recruit .wrapper-foreign .koe_right p {
    line-height: 30px;
    font-size: 17px; }
  .page-recruit .wrapper-foreign #graph {
    width: 1000px;
    height: 366px;
    margin: 0px auto;
    margin-bottom: 50px; }
  .page-recruit .wrapper-foreign h2#title6 {
    width: 1000px;
    height: 90px;
    margin: 0px auto;
    margin-bottom: 20px; }
  .page-recruit .wrapper-foreign #contents {
    width: 1000px;
    margin: 0px auto; }
  .page-recruit .wrapper-foreign #footer {
    background: #0043a3;
    padding: 20px 0;
    color: #fff;
    font-family: "Roboto", sans-serif;
    text-align: center;
    font-size: 13px; }
  .page-recruit .wrapper-foreign #thanks {
    width: 900px;
    margin: 0 auto 100px;
    padding-top: 50px; }
  .page-recruit .wrapper-foreign .black_text6 {
    font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    color: #222;
    font-size: 17px;
    text-align: center;
    margin: -20px auto 50px;
    line-height: 29px;
    letter-spacing: 0.1em; }
  .page-recruit .wrapper-foreign #toppage {
    width: 350px;
    height: 80px;
    background: url(../images/thanks_toppage.jpg) no-repeat;
    text-indent: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    margin: 30px auto; }
  .page-recruit .wrapper-foreign #toppage a {
    width: 100%;
    height: 100%;
    text-indent: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden; }
  .page-recruit .wrapper-foreign #toppage:hover {
    opacity: 0.8; }
  .page-recruit .wrapper-foreign .black_text1 {
    font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    color: #000;
    font-size: 34px;
    text-align: center;
    font-weight: bold;
    margin: 0px auto 60px;
    letter-spacing: 0.05em; }
  .page-recruit .wrapper-foreign .black_text5 {
    width: 900px;
    font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    color: #555;
    font-size: 20px;
    text-align: left;
    margin-bottom: 60px;
    text-align: center;
    font-weight: bold; }

.archive-news .agents-news-h1 {
  margin-bottom: 2.5rem; }

.archive-news .sec-news {
  background-color: #F9F9F9;
  padding: 4rem 0 20rem; }
  @media screen and (max-width: 959.98px) {
    .archive-news .sec-news {
      padding: 2rem 0 5rem; } }
  .archive-news .sec-news .news {
    padding: 1.5rem 0 2.5rem;
    border-bottom: dashed 1px black;
    margin-bottom: 1rem; }
    .archive-news .sec-news .news .news-title {
      font-size: 1.5rem;
      font-weight: normal;
      color: #0A1331;
      margin-bottom: 1.75rem; }
      @media screen and (max-width: 959.98px) {
        .archive-news .sec-news .news .news-title {
          font-size: 1.375rem; } }
    .archive-news .sec-news .news p {
      line-height: 1.75; }
      .archive-news .sec-news .news p + p {
        margin-top: 1.5rem; }
      .archive-news .sec-news .news p a {
        color: -webkit-link; }

.archive-now .now-header {
  color: #002D6F;
  font-size: 2.75rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2.5rem; }
  @media screen and (max-width: 959.98px) {
    .archive-now .now-header {
      font-size: 1.75rem; } }

.archive-now .grid-main {
  position: relative; }

.archive-now .sec-now {
  background-color: #F9F9F9;
  padding: 5rem 0 20rem; }
  @media screen and (max-width: 959.98px) {
    .archive-now .sec-now {
      padding: 2.5rem 0 5rem; } }
  .archive-now .sec-now .now-socials {
    position: absolute;
    top: -3rem;
    right: 1rem; }
    @media screen and (max-width: 959.98px) {
      .archive-now .sec-now .now-socials {
        position: static;
        text-align: right;
        margin-bottom: .5rem; } }
    .archive-now .sec-now .now-socials .fb_iframe_widget span {
      vertical-align: baseline !important; }
  .archive-now .sec-now .now.now-preview {
    text-align: center;
    border-bottom: solid 1px #D9D9D9;
    margin-bottom: 2rem; }
    .archive-now .sec-now .now.now-preview .now-photo {
      overflow: hidden; }
      .archive-now .sec-now .now.now-preview .now-photo img {
        transition: transform .25s; }
      .archive-now .sec-now .now.now-preview .now-photo:hover img, .archive-now .sec-now .now.now-preview .now-photo:focus img {
        transform: scale(1.05); }
    .archive-now .sec-now .now.now-preview .now-body {
      padding: 1.375rem 0; }
      .archive-now .sec-now .now.now-preview .now-body .now-title {
        margin-bottom: 1rem; }
        @media screen and (max-width: 959.98px) {
          .archive-now .sec-now .now.now-preview .now-body .now-title {
            font-size: 1.25rem; } }
        .archive-now .sec-now .now.now-preview .now-body .now-title a {
          display: block; }
  .archive-now .sec-now .now .now-photo {
    display: block;
    position: relative;
    padding-top: 42.509603072983355%;
    background-color: #ccc; }
  .archive-now .sec-now .now .now-body {
    padding: 2.5rem 0; }
    .archive-now .sec-now .now .now-body .now-title {
      color: #0A1331;
      font-size: 1.5625rem;
      font-weight: normal;
      margin-bottom: 3rem; }
      @media screen and (max-width: 959.98px) {
        .archive-now .sec-now .now .now-body .now-title {
          font-size: 1.375rem;
          margin-bottom: 2rem; } }
    .archive-now .sec-now .now .now-body .now-read-more {
      margin-top: 2.5rem; }
