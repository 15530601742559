.page-index {
	.index-h2 {
		font-size: 2.5rem;
		font-weight: normal;
		text-align: center;
		margin-bottom: 4rem;
		@include md {
			font-size: 1.75rem;
			margin-bottom: 2.5rem;
		}
		& + .index-h2-sub {
			font-size: .875rem;
			text-align: center;
			line-height: 1.875;
			margin-top: -3.5rem;
			margin-bottom: 3.5rem;
			@include md {
				margin-top: -2.5rem;
				margin-bottom: 2rem;
			}
		}
	}
	.sec-visual {
		.wrapper {
			position: relative;
			background-image: url(../img/index/visual.jpg);
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			min-height: 654px;
			@include hdpi(2) {
				background-image: url(../img/index/visual@2x.jpg);
			}
			@include md {
				min-height: 0;
			}
			section {
				position: absolute;
				top: 23%;
				left: 8%;
				width: 41.666666666666667%;
				font-size: .8125rem;
				@include md {
					position: static;
					width: auto;
					background-color: rgba(255,252,242,.85);
					padding: 2rem;
				}
				h1 {
					font-size: 2rem;
					font-weight: normal;
					margin-bottom: 1rem;
				}
				p {
					line-height: 1.875;
					& + p {
						margin-top: 1rem;
					}
				}
			}
		}
	}
	.sec-news {
		dl.news {
			display: inline-flex;
			flex-direction: column;
			text-align: left;
			max-width: 100%;
			& > div {
				display: flex;
				margin-bottom: .5rem;
				@include md {
					display: block;
					margin-bottom: .75rem;
				}
				dt {
					flex: none;
					margin-right: 2rem;
				}
				dd {
					flex: 1 1 auto;
					word-break: break-all;
				}
			}
		}
		.news-footer {
			text-align: right;
			margin-top: 1rem;
			@include md {
				margin-top: .25rem;
			}
		}
	}
	.sec-now {
		.now-header {
			position: relative;
			@include md {
				text-align: center;
			}
			.btn-gray {
				position: absolute;
				right: 0;
				bottom: 0;
				@include md {
					display: none;
				}
			}
		}
		.now-footer {
			display: none;
			@include md {
				display: block;
				text-align: right;

			}
		}
	}
	.sec-agents {
		.agents-footer {
			text-align: right;
		}
	}
	.sec-access {
		background-color: #F7F7F7;
		padding: 5rem 0;
		.access-header {
			margin-bottom: 1.75rem;
		}
		p {
			font-size: .8125rem;
			margin-bottom: 1.5rem;
		}
		.google-map iframe {
	        height: 460px;
		}
	}
}
