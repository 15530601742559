// mixinsの読み込み
@import "./mixins/include.scss";
@import "_variables.scss";
/******************************************
* font-size
******************************************/
@mixin font-size($size) {
    font-size:#{72 + (7 * ($size - 10)) * 1%};
}

/******************************************
* ie11のみ適応
******************************************/
@mixin ie11() {
    @media all and (-ms-high-contrast:none){
        @content;
    }
}

@for $i from 0 through 30 {
    .mt#{$i * 5} {margin-top:#{$i * 5}px!important;}
    .mr#{$i * 5} {margin-right:#{$i * 5}px!important;}
    .mb#{$i * 5} {margin-bottom:#{$i * 5}px!important;}
    .ml#{$i * 5} {margin-left:#{$i * 5}px!important;}

    .pt#{$i * 5} {padding-top:#{$i * 5}px!important;}
    .pr#{$i * 5} {padding-right:#{$i * 5}px!important;}
    .pb#{$i * 5} {padding-bottom:#{$i * 5}px!important;}
    .pl#{$i * 5} {padding-left:#{$i * 5}px!important;}
}

@for $i from 0 through 20 {
    .mt#{$i * -5} {margin-top:#{$i * -5}px!important;}
    .mr#{$i * -5} {margin-right:#{$i * -5}px!important;}
    .mb#{$i * -5} {margin-bottom:#{$i * -5}px!important;}
    .ml#{$i * -5} {margin-left:#{$i * -5}px!important;}
}


// break-points
@mixin xs()
{
  @media screen and (max-width: 479.98px)
  {
    @content;
  }
}
@mixin sm()
{
  @media screen and (max-width: 599.98px)
  {
    @content;
  }
}
@mixin md()
{
  @media screen and (max-width: 959.98px)
  {
    @content;
  }
}
@mixin lg()
{
  @media screen and (max-width: 1279.98px)
  {
    @content;
  }
}

@mixin sm-only()
{
  @media screen and (min-width: 480px) and (max-width: 599.98px)
  {
    @content;
  }
}
@mixin md-only()
{
  @media screen and (min-width: 600px) and (max-width: 959.98px)
  {
    @content;
  }
}
@mixin lg-only()
{
  @media screen and (min-width: 960px) and (max-width: 1279.98px)
  {
    @content;
  }
}
@mixin xl-only()
{
  @media screen and (min-width: 1280px)
  {
    @content;
  }
}

/******************************************
* common-style
******************************************/
* {
    box-sizing: border-box;
    margin: 0;
}
a {
    color: inherit;
    text-decoration: none;
    &.a-deco {
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}
br.wbr {
    @include sp {
        display: none;
    }
}
img {
    max-width: 100%;
    height: auto;
    border: none;
    &.mix-darken {
        mix-blend-mode: darken;
    }
    &.fit-cover {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
    }
}
.fit-parent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.opacify {
    @include hover-focus;
}

table {
    border-collapse: collapse;
}
address {
    font-style: normal;
}

html {
    scroll-behavior: smooth;
    &.is-locked {
        @include md {
            overflow: hidden;
        }
    }
}
body {
    padding: 0;
    margin: 0;
    line-height: 1.5;
    font-family: $gothic;
}

#grid-page-container {
    min-height: 100vh;
    display: grid;
    grid-template:
        "page-header" auto
        "page-main" 1fr
        "page-footer" auto
        /100%;
    #grid-page-header { grid-area: page-header }
    #grid-page-main { grid-area: page-main; padding-bottom: 8rem; }
    #grid-page-footer { grid-area: page-footer }
}

.list-none {
    list-style: none;
    padding-left: 0;
}
.list-before-square {
    & > li {
        &::before {
            content: "■";
        }
    }
}

.container {
    padding-left: $min-padding !important;
    padding-right: $min-padding !important;
}
.wrapper {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}
.wrapper-sm {
    width: 100%;
    max-width: 1040px;
    margin: 0 auto;
}
.wrapper-xs {
    width: 100%;
    max-width: 890px;
    margin: 0 auto;
}
.sec {
    margin-bottom: 8rem;
    @include md {
        margin-bottom: 5rem;
    }
}

.ws-pl {
    white-space: pre-line;
}
.ws-nw {
    white-space: nowrap;
}
.align-left {
    text-align: left !important;
}
.align-center {
    text-align: center !important;
}
.align-right {
    text-align: right !important;
}
.align-middle {
    vertical-align: middle !important;
}
.negative-indent {
    text-indent: -1em;
    padding-left: 1em;
}

.text-required {
    color: $required-color;
}
.text-danger {
    color: #D43F3A;
}
.text-info {
    color: #174C88;
}
.text-muted {
    color: #555555;
}
.text-associate {
    color: #D63367 !important;
}
.text-black {
    color: black !important;
}

.ti-1 {
    text-indent: 1em;
}

.font-sm {
    font-size: .875rem !important;
}
.font-xs {
    font-size: .75rem !important;
}
.font-lg {
    font-size: 1.25rem !important;
}
.font-xl {
    font-size: 1.5rem !important;
}
.font-bold {
    font-weight: bold;
}
.bg-gray {
    background-color: #F7F7F7;
}

@media screen {
    .screen-reader-text {
        display: none;
    }
}

.common-h1 {
    font-size: 2.75rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 4rem;
    @include md {
        font-size: 1.75rem;
        margin-bottom: 2.5rem;
    }
}
.common-h2 {
    color: #002D6F;
    font-size: 2.125rem;
    text-align: center;
    margin-bottom: 4rem;
    @include md {
        font-size: 1.5rem;
        margin-bottom: 2rem;
        img{
            height: 50px;
        }
    }
    & + .common-h2-sub {
        font-size: 1.1875rem;
        font-weight: bold;
        text-align: center;
        margin-top: -3.75rem;
        margin-bottom: 4rem;
        @include md {
            margin-top: -2rem;
            margin-bottom: 2rem;
        }
    }
}
.common-h3 {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    &.common-h3-left-border {
        padding-left: .75rem;
        border-left: solid 5px #56B9D6;
    }
}

.common-container {
    padding: 0 20px;
    @include md {
        padding: 0;
    }
}

.common-table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    color: #5e6568;
    tr {
        &:nth-of-type(even) {
            background-color: #e2e2e2;
        }
        th, td {
            text-align: left;
            padding: 1rem 2rem;
            border: solid 1px #c6c6c6;
        }
        th {
            font-weight: normal;
            width: 28%;
        }
    }
}

.agents-news-h1 {
    font-size: 2.75rem;
    text-align: center;
    margin-bottom: 5rem;
    @include md {
        font-size: 1.75rem;
        margin-bottom: 3.5rem;
    }
    .ja {
        color: #6C6C6C;
        font-weight: normal;
    }
    .en {
        font-weight: bold;
        @include md {
            display: inline-block;
        }
    }
}

.table-col-2 {
    th, td {
        text-align: left;
    }
    th {
        min-width: 10em;
        font-weight: normal;
        vertical-align: top;
    }
    @include md {
        display: block;
        tbody, tr, th, td {
            display: block;
            width: auto;
        }
        tr {
            margin-bottom: .75rem;
        }
        th {
            font-weight: bold;
        }
        td {
            padding-left: 1.5em;
            text-indent: 0;
        }
    }
}

.grid-col-2 {
    display: grid;
    grid-gap: 20px;
    grid-template:
    "left right" auto
    /1fr  1fr;
    @include md {
        display: block;
        .grid-left,
        .grid-right {
            margin-bottom: 4rem;
        }
        &.grid-left-auto,
        &.grid-right-auto {
            .grid-left,
            .grid-right {
                margin-bottom: 2rem;
            }
        }
    }
    &.grid-col-remain {
        @include md {
            display: grid;
        }
    }
    &.grid-align-top {
        .grid-left,
        .grid-right {
            align-self: flex-start;
        }
    }
    &.grid-align-center {
        .grid-left,
        .grid-right {
            align-self: center;
        }
    }
    &.grid-align-bottom {
        .grid-left,
        .grid-right {
            align-self: flex-end;
        }
    }
    &.grid-no-gap {
        grid-gap: 0;
        grid-template:
        "left right" auto
        /1fr  1fr;
    }
    &.grid-wide-gap {
        grid-gap: 40px;
        grid-template:
        "left right" auto
        /1fr  1fr;
    }
    &.grid-left-auto {
        grid-gap: 40px;
        grid-template:
        "left right" auto
        /auto 1fr;
        &.grid-no-gap {
            grid-gap: 0;
            grid-template:
            "left right" auto
            /auto 1fr;
        }
    }
    &.grid-right-auto {
        grid-gap: 40px;
        grid-template:
        "left right" auto
        /1fr  auto;
        &.grid-no-gap {
            grid-gap: 0;
            grid-template:
            "left right" auto
            /1fr  auto;
        }
    }
    .grid-left {
        grid-area: left;
    }
    .grid-right {
        grid-area: right;
    }
}
.grid-col-3 {
    display: grid;
    grid-gap: 20px;
    grid-template:
        "g1  g2  g3" auto
        /1fr 1fr 1fr;
    @include md {
        display: block;
    }
    &.grid-col-fixed {
        @include md {
            display: grid;
        }
    }
    .grid-1 {
        grid-area: g1;
    }
    .grid-2 {
        grid-area: g2;
    }
    .grid-3 {
        grid-area: g3;
    }
}

.grid-page-col {
    display: grid;
    grid-gap: 80px;
    grid-template:
    "main side" auto
    /1fr  300px;
    @include lg {
        grid-gap: 40px;
        grid-template:
        "main side" auto
        /1fr  300px;
    }
    @include md {
        display: block;
    }
    .grid-main {
        grid-area: main;
        @include md {
            margin-bottom: 5rem;
        }
    }
    .grid-side {
        grid-area: side;
    }
}

.col {
    overflow: hidden;

    .col-3 {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
        & > * {
            width: calc(100% / 3 - 10px * 2 - 1px);
            max-width: calc(100% / 3 - 10px * 2);
            flex: 1 0 auto;
            margin: 0 10px 20px;
        }
        @include md {
            & > * {
                width: calc(100% / 2 - 10px * 2 - 1px);
                max-width: calc(100% / 2 - 10px * 2);
                margin: 0 10px 20px;
            }
        }
        @include sm {
            margin: 0;
            & > * {
                width: 100%;
                max-width: none;
                margin: 0 0 20px;
            }
        }
    }
    .col-3m {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -35px;
        & > * {
            width: calc(100% / 3 - 35px * 2 - 1px);
            max-width: calc(100% / 3 - 35px * 2);
            flex: 1 0 auto;
            margin: 0 35px 20px;
        }
        @include md {
            margin: 0 -30px;
            & > * {
                width: calc(100% / 2 - 30px * 2 - 1px);
                max-width: calc(100% / 2 - 30px * 2);
                margin: 0 30px 20px;
            }
        }
        @include sm {
            margin: 0;
            & > * {
                width: 100%;
                max-width: none;
                margin: 0 0 20px;
            }
        }
    }}

.btn {
    @include hover-focus;
    display: inline-block;
    background: none;
    padding: 0;
    border: none;
    cursor: pointer;
    &.btn-gray {
        background-color: #BBBBBB;
        color: white;
        font-size: .8125rem;
        text-align: center;
        padding: .875rem 1rem;
        min-width: 166px;
        @include sm {
            padding: .5rem 1rem;
            min-width: 0;
        }
    }
    &.btn-sns {
        width: 26px;
        height: 26px;
        background-repeat: no-repeat;
        background-position: center;
        &.btn-sns-fb {
            background-color: #2f58a4;
            background-image: url(../img/fb.png);
            background-size: 10px 16px;
            @include hdpi(2) {
                background-image: url(../img/fb@2x.png);
            }
        }
        &.btn-sns-tw {
            background-color: #00b2e8;
            background-image: url(../img/tw.png);
            background-size: 16px 14px;
            @include hdpi(2) {
                background-image: url(../img/tw@2x.png);
            }
        }
    }
    &.btn-navy,
    &.btn-blue,
    &.btn-red {
        color: white;
        font-size: 1.125rem;
        font-weight: bold;
        text-align: center;
        padding: .75rem 2.5rem;
        border-radius: 10rem;
    }
    &.btn-red {
        background-color: #980114;
        min-width: 432px;
        @include sm {
            min-width: 0;
        }
    }
    &.btn-blue {
        background-color: #174C88;
        min-width: 316px;
        @include sm {
            min-width: 0;
        }
    }
    &.btn-navy {
        background-color: #0A1331;
        font-weight: normal;
        min-width: 213px;
        @include sm {
            min-width: 0;
        }
    }
}

.card {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px !important;
    @include sm {
        margin-bottom: 20px !important;
    }
    &:hover,
    &:focus {
        .card-photo .fit-parent img {
            transform: scale(1.05);
        }
    }
    .card-photo {
        flex: none;
        position: relative;
        background-color: #ddd;
        padding-top: 60.5263157894737%;
        overflow: hidden;
        .fit-parent {
            overflow: hidden;
            img {
                transition: transform .25s;
            }
        }
        time {
            position: absolute;
            display: block;
            font-size: .75rem;
            left: 0;
            bottom: 0;
            background-color: black;
            color: white;
            padding: .5rem 1rem;
        }
    }
    .card-body {
        flex: 1 0 auto;
        display: flex;
        min-height: 130px;
        flex-direction: column;
        justify-content: space-between;
        background-color: #F7F7F7;
        padding: 1rem 1.5rem;
        .card-title {
            flex: none;
            font-size: .75rem;
            font-weight: bold;
            margin-bottom: .5rem;
        }
        .card-text {
            flex: none;
            font-size: .75rem;
            margin-bottom: .5rem;
        }
        .card-tag {
            flex: none;
            a {
                display: inline-block;
                background-color: white;
                font-size: .75rem;
                padding: .25rem .75rem;
                border: solid 1px #DEDDDE;
                transition: .25s;
                &:hover,
                &:focus {
                    background-color: #4360AA;
                    color: white;
                    border-color: #4360AA;
                }
            }
        }
    }
}

.sec-title-img {
    margin-bottom: .75rem;
}

.side-contents {
    .side-content {
        margin-bottom: 2rem;
        dt {
            font-size: 1.125rem;
            font-weight: bold;
            margin-bottom: 2rem;
        }
        dd {
            .recommend-articles {
                li {
                    margin-bottom: 2rem;
                    a {
                        &:hover {
                            .ra-photo {
                                img {
                                    transform: scale(1.05);
                                }
                            }
                            .ra-title {
                                text-decoration: underline;
                            }
                        }
                        .ra-photo {
                            overflow: hidden;
                            position: relative;
                            padding-top: 42.509603072983355%;
                            margin-bottom: 1rem;
                            img {
                                transition: transform .25s;
                            }
                        }
                        .ra-title {
                            color: #0A1331;
                        }
                    }
                }
            }
        }
        &.side-content-navy,
        &.side-content-white {
            border-radius: 5px;
            padding: 2rem;
        }
        &.side-content-navy {
            background-color: #0A1331;
            color: white;
        }
        &.side-content-white {
            background-color: white;
            border: solid 1px #E0E0E0;
        }
        &.side-content-angle-list {
            dt {
                font-size: 1rem;
                font-weight: normal;
            }
            dd {
                ul {
                    li {
                        margin-bottom: .5rem;
                        a {
                            display: block;
                            padding-left: 1em;
                            text-indent: -1em;
                            &::before {
                                content: "＞";
                            }
                            &:hover,
                            &:focus {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }

}

.navigation.pagination[role="navigation"] {
    .screen-reader-text {
        clip: rect(1px, 1px, 1px, 1px);
        position: absolute !important;
        height: 1px;
        width: 1px;
        overflow: hidden;
    }
    .nav-links {
        .page-numbers {
            display: inline-block;
            background-color: #ccc;
            color: white;
            padding: .5rem 1rem;
            text-decoration: none;
            text-shadow: 0 -1px 0 rgba(0,0,0,.125);
            border-radius: 1px;
            margin-bottom: .25rem;
            &.current {
                background-color: #999;
            }
            &:hover,
            &:focus {
                background-color: #B3B3B3;
            }
        }
    }
}

.mb-sec {
    margin-bottom: 7rem !important;
}
.mb-0 { margin-bottom: 0 !important }

.mb-1 { margin-bottom: .25rem !important }
.mb-2 { margin-bottom: .5rem !important }
.mb-1.mb-2 { margin-bottom: .75rem !important }

.mb-4 { margin-bottom: 1rem !important }
.mb-4.mb-1 { margin-bottom: 1.25rem !important }
.mb-4.mb-2 { margin-bottom: 1.5rem !important }
.mb-4.mb-1.mb-2 { margin-bottom: 1.75rem !important }

.mb-8 { margin-bottom: 2rem !important }
.mb-8.mb-1 { margin-bottom: 2.25rem !important }
.mb-8.mb-2 { margin-bottom: 2.5rem !important }
.mb-8.mb-1.mb-2 { margin-bottom: 2.75rem !important }
.mb-8.mb-4 { margin-bottom: 3rem !important }
.mb-8.mb-4.mb-1 { margin-bottom: 3.25rem !important }
.mb-8.mb-4.mb-2 { margin-bottom: 3.5rem !important }
.mb-8.mb-4.mb-1.mb-2 { margin-bottom: 3.75rem !important }

.mb-16 { margin-bottom: 4rem !important }

.sec-btn {
    .flex {
        display: flex;
        width: 100%;
        .img-wrapper {
            flex: none;
            img {
                display: block;
            }
        }
        .title-wrapper {
            flex: 1 1 0%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1rem;
            img {
                display: block;
            }
            .title {
                display: block;
                font-size: 1.375rem;
                font-weight: bold;
                margin-top: .5rem;
            }
        }
        &.recruit,
        &.gray {
            .title-wrapper {
                background-color: #F9F9F9;
            }
        }
        &.introduce {
            .title {
                color: #123975;
            }
        }
    }
}

.text {
    font-size: 1rem;
    .text-header {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 1.5rem;
    }
    .text-subheader {
        font-size: 1.125rem;
        font-weight: bold;
        margin-bottom: 1rem;
    }
    section {
        margin-bottom: 4rem;
    }
    a {
        color: #174C88;
        &:hover {
            text-decoration: underline;
        }
    }
    p, ol, ul {
        line-height: 2;
        margin-bottom: 2rem;
    }
    ol, ul {
        padding-left: 1.5em;
        &.list-upper-roman {
            list-style-type: upper-roman;
        }
        &.list-none {
            list-style: none;
            padding-left: 0;
        }
    }
    hr {
        border-color: #979797;
        border-style: dashed none none;
        border-width: 1px;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    .border-box {
        padding: .5rem 1.25rem;
        border: solid 1px #979797;
        margin-bottom: 1.5rem;
    }
    .mb-p {
        margin-bottom: 2rem;
    }
}

.of-hidden {
    overflow: hidden;
}
.f-left {
    float: left;
    margin-right: 2rem;
    margin-bottom: 1rem;
}
.f-right {
    float: right;
    margin-left: 2rem;
    margin-bottom: 1rem;
}

.header-common {
    margin-bottom: 5rem;
    .grid-left {
        img {
            display: block;
            max-width: 332px;
        }
    }
    .grid-right {
        background-color: #F9F9F9;
        font-weight: bold;
        padding: 2rem;
    }
}

.box-lightblue {
    background-color: #D6E7F8;
    padding: 4rem 3.5rem 3rem;
    border-radius: 6px;
}

.google-map {
    iframe {
        width: 100%;
        border: none;
    }
}

.agent {
    display: grid;
    margin-bottom: 4rem !important;
    grid-gap: 20px;
    grid-template:
    "agt-photo agt-name" auto
    "agt-photo agt-sns"  auto
    "agt-intro agt-intro" 1fr
    /113px     1fr;
    .grid-photo {
        grid-area: agt-photo;
        .agent-photo {
            position: relative;
            border: solid 1px #DEDDDE;
            border-radius: 50%;
            overflow: hidden;
            &::before {
                content: "";
                display: block;
                padding-top: 100%;
            }
        }
    }
    .grid-name {
        grid-area: agt-name;
        .agent-pos {
            font-size: .5625rem;
        }
        .agent-name {
            font-size: 1.5rem;
        }
    }
    .grid-sns {
        grid-area: agt-sns;
        align-self: flex-end;
        font-size: .75rem;
        text-align: right;
    }
    .grid-intro {
        grid-area: agt-intro;
        font-size: .75rem;
    }
}

dl.contact-form {
    & > div {
        margin-bottom: 3rem;
        @include md {
            margin-bottom: 2rem;
        }
        dt {
            font-size: 1.5rem;
            font-weight: bold;
            padding-left: 1rem;
            margin-bottom: .5rem;
            @include md {
                font-size: 1.25rem;
            }
        }
        dd {
            &.dd-way {
                padding-left: 1rem;
                .err-msg {
                    padding-left: 0;
                }
            }
            input,
            textarea,
            select {
                display: block;
                width: 100%;
                font-size: 1.5rem;
                border: solid 3px #DEDDDE;
                border-radius: 17px;
                &:disabled {
                    opacity: .75;
                }
                @include md {
                    font-size: 1rem;
                }
            }
            input, textarea {
                padding: 1.625rem 2.25rem;
                @include md {
                    padding: 1rem 1.75rem;
                }
                &::placeholder {
                    color: #DEDDDE;
                }
            }
            textarea {
                resize: vertical;
                min-height: 320px;
            }
            select {
                background: none;
                appearance: none;
                background-image: url(../img/angle-down.png);
                background-size: 25px 16px;
                background-repeat: no-repeat;
                background-position: center right;
                background-position: center right 7%;
                padding: 1rem 2.25rem;
                @include hdpi(2) {
                    background-image: url(../img/angle-down@2x.png);
                }
                @include md {
                    padding: 1rem 1.75rem;
                }
                &::-ms-expand {
                    display: none;
                }
            }
            .err-msg {
                color: #D43F3A;
                font-size: 1.125rem;
                padding-left: 1rem;
                margin-top: .5rem;
                &:empty {
                    display: none;
                }
            }
            .label-check {
                display: inline-block;
                margin-top: 1rem;
                margin-right: 3rem;
                [type="checkbox"],
                [type="radio"] {
                    display: none;
                    &:checked + label .label-box::after {
                        content: "";
                    }
                    &:disabled + label {
                        opacity: .75;
                    }
                }
                label {
                    display: inline-block;
                    .label-box {
                        position: relative;
                        display: inline-block;
                        width: 2.75em;
                        height: 2.75em;
                        border: solid 3px #DEDDDE;
                        border-radius: 6px;
                        margin-right: 1em;
                        vertical-align: middle;
                        &::after {
                            content: none;
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            margin: auto;
                            display: block;
                            width: .5em;
                            height: 1.25em;
                            border-color: black;
                            border-style: none solid solid none;
                            border-width: .25em;
                            transform: translateY(-.125em) rotate(45deg);
                        }
                    }
                    .label-str {
                        user-select: none;
                        vertical-align: middle;
                    }
                }
            }
            .scroll-box-wrapper {
                padding: 1.625rem 0;
                border: solid 3px #DEDDDE;
                @include md {
                    padding: 1rem 0;
                }
                .scroll-box {
                    padding: 0 2.25rem;
                    max-height: 262px;
                    overflow: auto;
                    overflow-x: hidden;
                    @include md {
                        padding: 0 1rem;
                    }
                    p {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
.contact-submit {
    text-align: center;
    margin-top: 5rem;
    @include md {
        margin-top: 3.5rem;
    }
}
/* wordpress editor -------- */
.editor {
    overflow: hidden;
    & > :first-child {
        margin-top: 0;
    }
    .aligncenter {
        display: block;
        margin: 0 auto;
        padding-bottom: 20px;
    }
    .alignright {
        float: right;
        padding-left: 20px;
        padding-bottom: 20px;
        @include sp {
            float: none;
            padding-left: 0;
            padding-bottom: 0;
            width: 100%;
        }
    }
    .alignleft {
        float: left;
        padding-right: 20px;
        padding-bottom: 20px;
        @include sp {
            float: none;
            padding-right: 0;
            padding-bottom: 0;
            width: 100%;
        }
    }
    img[class*="wp-image-"], img[class*="attachment-"] {
        max-width: 100%;
        height: auto;
    }
    a {
        color: $link-color;
        text-decoration: none;
        &:hover {
            color: #69f;
        }
    }
    h1 {
        margin: 50px 0 40px;
        font-size: 1.5rem;
        font-weight: normal;
        line-height: 1.25;
        @include sp {
            font-size: 1.25rem;
        }
    }
    h2 {
        margin: 40px 0 30px;
        font-size: 150%;
        font-weight: bold;
        line-height: 1.25;
    }
    h3 {
        margin: 30px 0 20px;
        font-size: 125%;
        font-weight: bold;
        line-height: 1.25;
    }
    p, ul, ol, table {
        font-size: .875rem;
        line-height: 1.875;
        margin-bottom: .875rem;
    }
    li {
        margin-bottom: 10px;
    }
    ul {
        list-style-type: disc;
        padding-left: 1.5em;
    }
    ol {
        list-style-type: decimal;
        padding-left: 1.5em;
    }
    hr {
        margin: 20px 0;
        border: none;
        border-top: 1px solid #999;
    }
    table {
        width: auto !important;
        height: auto !important;
        border-collapse: collapse;
    }
    th, td {
        padding: 10px 20px;
        border: 1px solid #b5b5b5;
    }
    th {
        background-color: #eee;
    }
    td {
    }
    strong {
        font-weight: bold;
    }
    em {
        font-style: italic;
    }
    del {
        text-decoration: line-through;
    }
}
































