#nav {
	@include md {
		position: absolute;
		z-index: 2147483647;
		top: 0;
		right: 0;
		width: 250px;
		background-color: #004D9A;
		color: white;
		padding: 1.25rem 1.5rem;
		opacity: 0;
		visibility: hidden;
		transition: visibility .25s step-end, opacity .25s;
		&.is-shown {
			opacity: 1;
			visibility: visible;
			transition: visibility .25s step-start, opacity .25s;
			& + #backdrop {
				opacity: 1;
				visibility: visible;
				background-color: rgba(255,255,255,.25);
				transition: visibility .25s step-start, all .25s;
			}
		}
	}
	.nav-header {
		display: none;
		@include md {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 1.5rem;
		}
	}
	ul {
		li {
			display: inline-block;
			font-size: .8125rem;
			font-weight: bold;
			margin-right: 3em;
			&:last-of-type {
				margin-right: 0;
			}
			@include md {
				font-weight: normal;
				margin-right: 0;
			}
			a {
				@include hover-focus;
				&.curr {
					color: #DB1C2E;
					@include md {
						color: white;
						font-weight: bold;
					}
				}
			}
			@include md {
				display: block;
				font-size: .875rem;
				a {
					display: block;
					padding: .5rem 0;
				}
			}
		}
	}
}
#backdrop {
	display: none;
	@include md {
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 2147483646;
		opacity: 0;
		visibility: hidden;
		transition: visibility .25s step-end, all .25s;
	}
}
.btn-menu {
	display: block;
	background: none;
	font-size: inherit;
	padding: 0;
	border: none;
	text-align: center;
	width: 50px;
	margin-left: auto;
	.btn-menu-icon {
		position: relative;
		width: 50px;
		height: 39px;
		margin: 2px auto 0;
		.hamburger {
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto;
			width: 100%;
			height: 9px;
			background-color: black;
			&::before,
			&::after {
				content: "";
				position: absolute;
				left: 0;
				width: 100%;
				height: 9px;
				background-color: black;
			}
			&::before {
				bottom: 15px;
			}
			&::after {
				top: 15px;
			}
		}
		.cross {
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto;
			&::before,
			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: 16px;
				height: 3px;
				background-color: white;
				transform-origin: center;
				margin: auto;
			}
			&::before {
				transform: rotate(45deg);
			}
			&::after {
				transform: rotate(-45deg);
			}
		}
	}
	.btn-menu-label {
		font-size: 1.25rem;
	}
	&#openMenu {
	}
	&#closeMenu {
		display: inline-block;
		width: auto;
		color: white;
		text-align: center;
		line-height: 1;
		.btn-menu-icon {
			width: 16px;
			height: 16px;
			margin: 0 auto;
		}
		.btn-menu-label {
			font-size: .5625rem;
		}
	}
}
