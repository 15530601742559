#header {
	padding: 2rem 0;
	.grid-header {
		display: grid;
		grid-gap: 40px;
		grid-template:
		"h-logo ... h-nav" auto
		/auto   1fr auto;
		@include md {
			grid-template:
			"h-logo h-nav" auto
			/1fr    auto;
		}
		.grid-logo {
			grid-area: h-logo;
			align-self: center;
		}
		.grid-nav {
			grid-area: h-nav;
			align-self: center;
			position: relative;
			#openMenu {
				display: none;
				@include md {
					display: block;
				}
			}
		}
	}
}
