.page-business {
	.common-h1 {
		margin-bottom: 2.5rem;
	}
	.sec-prelude {
		margin-bottom: 2rem;
		.common-h2 {
			margin-bottom: 2.5rem;
		}
		.prelude-footer {
			margin-top: 5rem;
			@include md {
				margin-top: 3.5rem;
			}
		}
	}
	.sec-visual {
		background-color: #161439;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: top center;
		background-image: url(../img/business/visual.jpg);
		height: 57.266187050359712vw;
		color: white;
		text-align: center;
		padding-top: 7%;
		@include hdpi(2) {
			background-image: url(../img/business/visual@2x.jpg);
		}
		@include md {
			height: auto;
			min-height: 680px;
			padding: 4rem 0 12rem;
			text-shadow: 0 0 1px #161439;
		}
		.wrapper-visual {
			max-width: 850px;
			h2 {
				margin-bottom: 2rem;
			}
			p{
				@include md{
					background: rgba(0,0,0,0.25);
					padding: 15px;
					border-radius: 4px;
				}
			}
		}
	}
	.sec-group {
		.group-header {
			font-size: 1.6875rem;
			font-weight: bold;
			text-align: center;
			margin-bottom: 5rem;
			@include md {
				font-size: 1.5rem;
				margin-bottom: 3.5rem;
			}
		}
		.grid-company {
			grid-gap: 80px;
	        grid-template:
	        "left right" auto
	        /1fr  1fr;
		}
		.company {
			.company-photo {
				text-align: center;
				margin-bottom: 1rem;
			}
			.company-name {
				font-size: 1.6875rem;
				font-weight: bold;
				text-align: center;
				margin-bottom: 3rem;
				@include md {
					font-size: 1.375rem;
					margin-bottom: 2rem;
				}
			}
		}
	}
	.sec-colors {
		.grid-colors {
			margin-top: 5rem;
			grid-gap: 80px;
			grid-template:
			"g1  g2  g3" auto
			/1fr 1fr 1fr;
			text-align: center;
		}
		.grid-colors-col2{
			margin-top: 5rem;
			text-align: center;
		}
		.grid-1,
		.grid-2,
		.grid-3 {
			@include md {
				margin-bottom: 3.5rem;
			}
		}
		.merits-wrapper {
			overflow: hidden;
			margin-top: 4rem;
			.flex-merits {
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				margin: 0 -15px;
				.merit {
					flex: 1 0 auto;
					width: calc(100% / 3 - 30px - 1px);
					max-width: calc(100% / 3 - 30px);
					text-align: center;
					margin: 0 15px 30px;
					@include md {
						width: calc(100% / 2 - 30px - 1px);
						max-width: calc(100% / 2 - 30px);
					}
				}
			}
		}
		.point {
			text-align: center;
			margin-bottom: 2rem;
			@include md {
				margin-bottom: 1.5rem;
			}
			.point-square {
				position: relative;
				width: 100%;
				max-width: 136px;
				border: solid 1px #979797;
				margin: 0 auto .25rem;
				&::before {
					content: "";
					display: block;
					padding-top: 100%;
				}
				&.point-square-yellow {
					background-color: #F3B023;
					border: none;
				}
				&.point-square-blue {
					background-color: #4360AA;
					color: white;
					border: none;
				}
				&.point-square-red {
					background-color: #D43F3A;
					color: white;
					border: none;
				}
				&.point-square-green {
					background-color: #345012;
					color: white;
					border: none;
				}
				.fit-parent {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					font-weight: bold;
					.point-number {
						font-size: 3.125rem;
						line-height: 1.25;
					}
				}
			}
			.point-label {
				font-size: 1.0625rem;
			}
		}
	}
	.sec-contact {
		.wrapper-contact {
			max-width: 660px;
		}
		.contact-header {
			color: #D43F3A;
			font-size: 2.125rem;
			font-weight: bold;
			text-align: center;
			margin-bottom: 2rem;
			@include md {
				padding-top: 2rem;
				font-size: 1.75rem;
			}
		}
		p {
			margin-bottom: 5rem;
			@include md {
				margin-bottom: 3.5rem;
			}
		}
	}
}
