.page-recruit {
	.wrapper-foreign {
		* {
			box-sizing: content-box;
		}
/*---------------共通設定----------------*/
html, body, div, h1, h2, h3, h4, h5, h6, p, blockquote, pre,
 addres, ul, ol, dl, dt, dd, table, th, td, form, fieldset{
  margin:0;
  padding:0;
  line-height:1.6;
	font-family:"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
		list-style-type:none;
			color:#000;
}

table,td{
  border-collapse:collapse;
  border-spacing:0;
}

ul,ol{
  list-style:none;
}
a{
text-decoration: none;
	color:#000;
	outline: none;
	overflow: hidden;

}

img{
 border:0;
}

ing,input{
  vertical-aligin: bottom;
}

body{
	background-color:#fff;
	margin:0px auto;
}


.clearfix:after {
          content: ".";
          display: block;
          height: 0;
          clear: both;
          visibility: hidden;
}
  
.clearfix {
          zoom: 1;
}



/*---------------------header-----------------------*/


#header{
	height:90px;
	width:1000px;
	margin:0px auto;
}


h1#logo{
	width:185px;
	height:61px;
	background:url(../img/recruit/logo.png) no-repeat;
	text-indent:100%;
	display:block;
	float:left;	
	white-space:nowrap;
	overflow:hidden;
	margin-top:15px;
}


#head_tel{
	width:250px;
	height:61px;
	float:right;
	padding-right:10px;
	margin-top:15px;
}

#head_mail{
	width:220px;
	height:52px;
	background:url(../img/recruit/head_mail.png) no-repeat;
	text-indent:100%;
	display:block;
	float:right;	
	white-space:nowrap;
	overflow:hidden;
	margin-top:19px;
}

#head_mail a{
	width:100%;
	height:100%;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
}

#head_mail:hover{
	opacity:0.8;
}


/*---------------------main-----------------------*/


#main{
	width:1000px;
	height:460px;
	background:url(../img/recruit/main3.jpg) no-repeat;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
	margin:0px auto;
	clear:both;
}

#mail a{
	width:100%;
	height:100%;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
}


/*---------------------check-----------------------*/


#check{
	width:1000px;
	height:100px;
	background:url(../img/recruit/check.jpg) no-repeat;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
	margin:0px auto;
}


#check_back{
	background:#00499c;
	height:100px;
}

#back1{
	background:url(../img/recruit/back1.jpg) repeat;
}

#check2{
	background:url(../img/recruit/check2.png) no-repeat;
	width:1000px;
	height:350px;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
	margin:0px auto;
}

#check2 a{
	width:100%;
	height:100%;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
}

#check3{
	background:url(../img/recruit/check3.jpg) no-repeat;
	width:1000px;
	height:450px;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
	margin:0px auto;
	margin-bottom:50px;
}

#check3 a{
	width:100%;
	height:100%;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
}


/*---------------------RE/MAXエージェントとは-----------------------*/


#title1{
	background:url(../img/recruit/title1.png) no-repeat;
	width:1000px;
	height:90px;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
	margin:0px auto;
	margin-bottom:20px;
}


.remax_p p{
	width:1000px;
	margin:0px auto;
	margin-bottom:30px;
	font-size:17px;
	letter-spacing:0.05em;
	line-height:30px;
	text-align: justify;
}

#table{
	width:1000px;
	height:359px;
	margin:0px auto;
	margin-bottom:50px;
}


/*---------------------RE/MAXエージェントの特徴-----------------------*/

#title2{
	background:url(../img/recruit/title2.jpg) no-repeat;
	width:1000px;
	height:90px;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
	margin:0px auto;
	margin-bottom:50px;
}



h3#tokucho1{
	background:url(../img/recruit/tokucho1.png) no-repeat;
	width:783px;
	height:96px;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
	margin-left:40px;
	margin-bottom:20px;
}

h3#tokucho2{
	background:url(../img/recruit/tokucho2.png) no-repeat;
	width:783px;
	height:96px;
	text-indent:200%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
	margin-left:40px;
	margin-bottom:20px;
}

h3#tokucho3{
	background:url(../img/recruit/tokucho3.png) no-repeat;
	width:783px;
	height:96px;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
	margin-left:40px;
	margin-bottom:20px;
}
/*
h3#tokucho4{
	background:url(../img/recruit/tokucho4.png) no-repeat;
	width:783px;
	height:96px;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
	margin-left:40px;
	margin-bottom:20px;
}

h3#tokucho5{
	background:url(../img/recruit/tokucho5.png) no-repeat;
	width:783px;
	height:96px;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
	margin-left:40px;
	margin-bottom:20px;
}

*/

.back_blue{
	background:#00499c repeat;
}

.back_in{
	width:1000px;
	background:url(../img/recruit/back1.jpg) repeat;
	margin:0px auto;
	border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
	padding-top:40px;
	padding-bottom:1px;
}

.image1 img{
	float:right;
	margin-right:40px;
	box-shadow:2px 2px 3px #ccc;
	-moz-box-shadow:2px 2px 3px #ccc;
	-webkit-box-shadow:2px 2px 3px #ccc;
}


.image2 img{
	float:right;
	margin-right:40px;
	box-shadow:2px 2px 3px #ccc;
	-moz-box-shadow:2px 2px 3px #ccc;
	-webkit-box-shadow:2px 2px 3px #ccc;
	margin-top:110px;
}

.image3 img{
	float:right;
	margin-right:40px;
	box-shadow:2px 2px 3px #ccc;
	-moz-box-shadow:2px 2px 3px #ccc;
	-webkit-box-shadow:2px 2px 3px #ccc;
	margin-top:-70px;
}


.tokucho_p{
	width:540px;
	margin-bottom:30px;
	font-size:17px;
	letter-spacing:0.05em;
	line-height:30px;
	float:left;
	padding-left:50px;
	font-weight:700;
	text-align: justify;
}

.tokucho_p2{
	width:360px;
	margin:0px auto;
	margin-bottom:30px;
	font-size:17px;
	letter-spacing:0.05em;
	line-height:30px;
	float:left;
	padding-left:50px;
	font-weight:700;
	text-align: justify;
}


.bottom20{
	margin-bottom:60px;
}

.tokucho_left{
	width:534px;
}

#tokucho_p3{
	width:540px;
	float:left;
	text-align: justify;
}

h4{
	color:#00499c;
	font-size:20px;
	font-weight:bold;
	padding-top:10px;
}

.tokucho_left{
	padding-left:50px;
	width:534px;
	font-weight:700;
	font-size:17px;
	letter-spacing:0.05em;
	line-height:30px;
}

#remaxtoha{
	margin-top:50px;	
}

#title_remax{
	background:url(../img/recruit/title_remax.jpg) no-repeat;
	width:1000px;
	height:90px;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
	margin:0px auto;
	margin-bottom:50px;
}

.black_font{
	width:900px;
	font-size:17px;
	letter-spacing:0.05em;
	line-height:30px;
	font-weight:700;
	text-align: justify;
	margin:0 auto 50px;
}


.remaxtoha_title{
	background-color:#333;
	width:900px;
	font-family:"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	color:#fff;
	font-size:26px;
	text-align:center;
	font-weight:bold;
	margin:60px auto 50px;
}

.remaxtoha_images{
	width:980px;
	margin:0 auto 60px;
}


/*---------------------お問合せ-----------------------*/


.inquiry_back1{
	background:#00499c repeat;
	padding-top:50px;
	padding-bottom:50px;
	margin-bottom:50px;
}

.inquiry_back2{
	width:1000px;
	height:330px;
	background:url(../img/recruit/inquiry_back.png) no-repeat;
	margin:0px auto;
}

.inquiry_back2-1{
	width:1000px;
	height:330px;
	background:url(../img/recruit/inquiry_back2.png) no-repeat;
	margin:0px auto;
}

.inquiry_back2-2{
	width:1000px;
	height:330px;
	background:url(../img/recruit/inquiry_back3.png) no-repeat;
	margin:0px auto;
}

.inquiry_back2-3{
	width:1000px;
	height:330px;
	background:url(../img/recruit/inquiry_back4.png) no-repeat;
	margin:0px auto;
}


.inquiry{
	width:670px;
	float:right;
	padding-right:40px;
	padding-top:40px;
}

.inquiry p{
	font-size:18px;
	font-weight:bold;
	margin-bottom:15px;
	text-align: justify;
}

.logo2{
	background:url(../img/recruit/logo2.png) no-repeat;
	width:66px;
	height:20px;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
	float:left;
	padding-right:5px;
	margin-top:3px;
}

.inquiry_mail{
	background:url(../img/recruit/inquiry_mail.png) no-repeat;
	width:670px;
	height:90px;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
	float:left;
	padding-right:5px;
}

.inquiry_mail a{
	width:100%;
	height:100%;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
}



.inquiry_tel{
	margin-bottom:10px;
}

.inquiry_mail:hover{
	opacity:0.8;
}



/*---------------------こだわり-----------------------*/


#title3{
	background:url(../img/recruit/title3.png) no-repeat;
	width:1000px;
	height:90px;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
	margin:0px auto;
	margin-bottom:20px;
}

#kodawari{
	width:1000px;
	margin:0px auto;
}

#kodawari p{
	width:1000px;
	margin:0px auto;
	margin-bottom:30px;
	font-size:17px;
	line-height:30px;
	letter-spacing:0.05em;
	text-align: justify;
}

#kodawari1{
	width:1000px;
	height:419px;
	margin:0px auto;
	margin-bottom:50px;
}


#ryotechukai{
	background:url(../img/recruit/ryotechukai.jpg) no-repeat;
	width:500px;
	height:419px;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
	float:left;
}

#katatechukai{
	background:url(../img/recruit/katatechukai.jpg) no-repeat;
	width:500px;
	height:419px;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
	float:right;
}

#kakoikomi{
	background:url(../img/recruit/kakoikomi.jpg) no-repeat;
	width:1000px;
	height:959px;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
}

#tesuryogaku{
	background:url(../img/recruit/tesuryogaku.jpg) no-repeat;
	width:1000px;
	height:234px;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
	margin-bottom:14px;
}

#kodawari3{
	background:url(../img/recruit/kodawari3.jpg) no-repeat;
	width:1000px;
	height:197px;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
	margin-bottom:50px;
}








/*---------------------収入-----------------------*/


#title4{
	background:url(../img/recruit/title4.png) no-repeat;
	width:1000px;
	height:90px;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
	margin:0px auto;
	margin-bottom:20px;
}

#income{
	width:1000px;
	margin:0px auto;
}


#income p{
	width:1000px;
	margin:0px auto;
	margin-bottom:30px;
	font-size:17px;
	line-height:30px;
	text-align: justify;
}

#income1{
	background:url(../img/recruit/income1.jpg) no-repeat;
	width:480px;
	height:564px;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
	float:left;
	margin-bottom:20px;
}

#income2{
	background:url(../img/recruit/income2.jpg) no-repeat;
	width:480px;
	height:564px;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
	float:right;
	margin-bottom:20px;
}

#income3{
	background:url(../img/recruit/income3.jpg) no-repeat;
	width:480px;
	height:564px;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
	float:left;
	margin-bottom:20px;
}

#income4{
	background:url(../img/recruit/income4.jpg) no-repeat;
	width:480px;
	height:564px;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
	float:right;
	margin-bottom:50px;
}





/*---------------------エージェントの声-----------------------*/


#title5{
	background:url(../img/recruit/title5.png) no-repeat;
	width:1000px;
	height:90px;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
	margin:0px auto;
	margin-bottom:50px;
}

.koe{
	width:918px;
	margin:0px auto;
	background-color:#fff;
	box-shadow:2px 2px 3px #ccc;
	-moz-box-shadow:2px 2px 3px #ccc;
	-webkit-box-shadow:2px 2px 3px #ccc;
	border:1px solid #eee;
	padding:40px;
	margin-bottom:50px;
}

.case{
	width:78px;
	height:78px;
	background:url(../img/recruit/case_back.png) no-repeat;
	float:left;
	color:#fff;
	font-size:44px;
	font-family:Arial, Helvetica, sans-serif;
	font-weight:bold;
	padding-top:15px;
	text-align:center;
	margin-right:20px;
}

.syozoku{
	font-size:22px;
}

.name{
	padding-right:20px;
	margin-top:-10px;
	float:left;
	font-family:"ＭＳ ゴシック", "MS Gothic", "Osaka－等幅", Osaka-mono, monospace;
	font-size:45px;
}

.kana{
	font-size:25px;
	padding-top:15px;
	font-family:Arial, Helvetica, sans-serif;
}

.koe_left p{
	width:650px;
	font-size:16px;
	border-left:3px solid #00499c;
	padding-left:18px;
	text-align: justify;
}

.koe_name{
	height:78px;
	border-bottom:2px dotted #ccc;
	margin-bottom:30px;
	padding-bottom:30px;
	line-height:90px;
}

.koe_right{
	width:210px;
	height:250px;
	float:right;
}


.koe_left{
	width:668px;
	float:left;
}


.koe_right img{
	margin-bottom:10px;
}


.koe_right p{
	line-height:30px;
	font-size:17px;

}


#graph {
	width:1000px;
	height:366px;
	margin:0px auto;
	margin-bottom:50px;
}



/*---------------------お問い合わせ・ご相談-----------------------*/


h2#title6{
	width:1000px;
	height:90px;
	margin:0px auto;
	margin-bottom:20px;
}


#contents{
	width:1000px;
	margin:0px auto;
}





/*---------------------footer-----------------------*/

#footer {
  background: #0043a3;
  padding: 20px 0;
  color: #fff;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-size:13px;
}

/*---------------------thanks-----------------------*/
#thanks{
	width:900px;
	margin:0 auto 100px;
	padding-top:50px;
}


.black_text6{
	font-family:"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	color:#222;
	font-size:17px;
	text-align:center;
	margin:-20px auto 50px;
	line-height:29px;
	letter-spacing:0.1em;
}

#toppage{
	width:350px;
	height:80px;
	background:url(../images/thanks_toppage.jpg) no-repeat;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
	margin:30px auto;
}

#toppage a{
	width:100%;
	height:100%;
	text-indent:100%;
	display:block;
	white-space:nowrap;
	overflow:hidden;
}

#toppage:hover{
	opacity:0.8;
}

.black_text1{
	font-family:"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	color:#000;
	font-size:34px;
	text-align:center;
	font-weight:bold;
	margin:0px auto 60px;
	letter-spacing:0.05em;
}

.black_text5{
	width:900px;
	font-family:"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	color:#555;
	font-size:20px;
	text-align:left;
	margin-bottom:60px;
	text-align:center;
	font-weight:bold;
}

	}
}
