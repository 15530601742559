// 変数の設定

//fonts
$mincho: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
$gothic: 游ゴシック体, 'Yu Gothic', YuGothic, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;

$dir: "../..";

$base-color: #2A4E86;
$text-black: #202124;
$text-red: #E60012;

$main-color: #01A0C7;
$border-color: #deddde;
$link-color: #0A1331;

$required-color: #FF0000;

$min-padding: 15px;
