.page-office {
	.sec-greeting {
		h3 {
			font-size: 1.875rem;
			margin-bottom: 1rem;
			@include md {
				font-size: 1.25rem;
			}
		}
		p {
			line-height: 2;
		}
		.greeting-footer {
			margin-top: 2.5rem;
			text-align: right;
			font-weight: bold;
			.pos {
				font-size: 1.0625rem;
				margin-bottom: .5rem;
				@include md {
					font-size: 1rem;
					margin-bottom: .25rem;
				}
			}
			.name {
				font-size: 2.0625rem;
				@include md {
					font-size: 1.5rem;
				}
			}
		}
	}
	.sec-associate {
		.lives {
			margin-bottom: 5rem;
			@include sp{
				text-align: center;
			}
			.live1 {
				b { color: #D43F3A }
				
			}
			.live2 {
				b { color: #4A90E2 }
			}
			.live3 {
				b { color: #417505 }
			}
		}
		.common-h2.text-associate {
			margin-bottom: 2rem;
		}
	}
	.gradient {
		padding-top: 5rem;
		background-image: linear-gradient(to bottom, #EEEEEE, rgba(216,216,216,0));
	}
	.google-map iframe {
		height: 591px;
	}
}
