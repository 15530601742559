#footer {
	position: relative;
	background-color: black;
	color: white;
		.flex-wrapper {
			display: flex;
			width: 100%;
			.flex {
				display: flex;
				width: 100%;
				align-items: center;
				min-height: 3.5rem;
				.social-btns {
					flex: none;
					line-height: 1;
					a + a {
						@include sm {
							margin-left: .5rem;
						}
					}
				}
				.copyright {
					flex: 1 0 0%;
					font-size: .8125rem;
					text-align: center;
					padding: .375rem 4rem .375rem 1rem;
					small {
						font-size: inherit;
					}
				}
			}
		}
		.totop {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			width: 3.5rem;
			background-color: #333333;
			overflow: hidden;
			&::before,
			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				width: .75rem;
				height: 2px;
				background-color: white;
			}
			&::before {
				transform: translateX(-30%) rotate(-45deg);
			}
			&::after {
				transform: translateX(30%) rotate(45deg);
			}
		}
}
