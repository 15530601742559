/* mixinsの読み込み */
@import "mixins/include.scss";
/* variablesの読み込み */
@import "_variables.scss";

@import "_common.scss";

// parts
@import "parts/_header.scss";
@import "parts/_nav.scss";
@import "parts/_footer.scss";

// pages
@import "pages/_page-index.scss";
@import "pages/_page-office.scss";
@import "pages/_page-business.scss";
@import "pages/_page-recruit.scss";
@import "pages/_archive-news.scss";
@import "pages/_archive-now.scss";
