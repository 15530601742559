.archive-news {
	.agents-news-h1 {
		margin-bottom: 2.5rem;
	}
	.sec-news {
		background-color: #F9F9F9;
		padding: 4rem 0 20rem;
		@include md {
			padding: 2rem 0 5rem;
		}
		.news {
			padding: 1.5rem 0 2.5rem;
			border-bottom: dashed 1px black;
			margin-bottom: 1rem;
			.news-title {
				font-size: 1.5rem;
				font-weight: normal;
				color: #0A1331;
				margin-bottom: 1.75rem;
				@include md {
					font-size: 1.375rem;
				}
			}
			p {
				line-height: 1.75;
				& + p {
					margin-top: 1.5rem;
				}
				a{
					color: -webkit-link;
				}
			}
		}
	}
}
